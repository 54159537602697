/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useState, SyntheticEvent } from "react";
import { ChipProps } from "@mui/material/Chip";

import { useStyleOptions } from "./hooks";
import { TAG_SIZES, TagProps } from "./types";

import ClearIcon from "../../../assets/customIcons/actions/ClearIcon";

const Tag = ({
    size,
    color,
    title,
    isNotSelectable,
    onClick,
    onDelete,
    ...props
}: TagProps & Omit<ChipProps, "size" | "color">) => {
    const [isSelected, setSelected] = useState(false);

    const styleVariants = useStyleOptions(isSelected, onDelete);

    const handleClick = (e: SyntheticEvent) => {
        e.stopPropagation();

        if (onClick) {
            !isNotSelectable && setSelected(!isSelected);
            onClick();
        }
    };

    const handleDelete = (e: SyntheticEvent) => {
        e.stopPropagation();
        onDelete && onDelete(e);
    };

    const bgColorRest = styleVariants.colors[color].backgroundRest;
    const bgColorHover = styleVariants.colors[color].backgroundHover;
    const bgColorActive = styleVariants.colors[color].backgroundActive;
    const textColorRest = styleVariants.colors[color].textRest;
    const textColorActive = styleVariants.colors[color].textActive;
    const borderColor = styleVariants.colors[color].border;
    const borderColorHover = styleVariants.colors[color].borderHover;
    const borderColorActive = styleVariants.colors[color].borderActive;
    const iconColor = styleVariants.colors[color].icon;
    const iconColorHover = styleVariants.colors[color].iconHover;
    const iconColorActive = styleVariants.colors[color].iconActive;

    const padding = styleVariants.paddings[size];

    const fontSize = size === TAG_SIZES.Tiny ? "12px" : "14px";
    const lineHeight = size === TAG_SIZES.Tiny ? "16px" : "20px";
    const letterSpacing = size === TAG_SIZES.Tiny ? "0.4px" : "0.1px";

    return (
        <div
            {...props}
            onClick={handleClick}
            css={css({
                display: "inline-flex",
                alignItems: "center",
                border: `1px solid ${borderColor}`,
                borderRadius: "4px",
                cursor: onClick ? "pointer" : "auto",
                transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                backgroundColor: bgColorRest,
                wordBreak: "break-word",
                padding,

                "&:hover": {
                    backgroundColor: onClick ? bgColorHover : bgColorRest,
                    borderColor: onClick ? borderColorHover : borderColor
                },

                "&:active": {
                    backgroundColor: onClick ? bgColorActive : bgColorRest,
                    borderColor: onClick ? borderColorActive : borderColor
                },

                "& > svg": {
                    color: iconColor,
                    fontSize: "16px",
                    marginLeft: "8px",
                    cursor: "pointer",
                    transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

                    "&:hover": {
                        color: iconColorHover
                    },

                    "&:active": {
                        color: iconColorActive
                    }
                }
            })}
        >
            <span
                css={css({
                    fontWeight: "600",
                    color: textColorRest,
                    fontSize,
                    lineHeight,
                    letterSpacing,

                    "&:active": {
                        color: textColorActive
                    }
                })}
            >
                {title}
            </span>

            {onDelete && <ClearIcon onClick={handleDelete} />}
        </div>
    );
};

export { TAG_SIZES, TAG_COLORS } from "./types/enums";

export default Tag;
