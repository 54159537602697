import { useContext } from "react";
import { Navigate } from "react-router-dom";

import EnableTwoFactorAuthentication from "./EnableTwoFactorAuthentication";

import AuthContext from "../../../context/auth/authContext";
import { ROUTES } from "../../../shared";

const TwoFactorRequirement = () => {
    const { isAuthenticated } = useContext(AuthContext);

    return isAuthenticated ? (
        <EnableTwoFactorAuthentication />
    ) : (
        <Navigate to={{ pathname: ROUTES.Login }} replace />
    );
};

export default TwoFactorRequirement;
