import moment from "moment";

import {
    SET_LOADING,
    REMOVE_LOADING,
    SET_USER,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    SET_COOKIE_CONSENT,
    UPDATE_USER_COMPANY,
    TOGGLE_2FA_VERIFICATION,
    REMOVE_ERROR_MESSAGE,
    TOGGLE_2FA_ENABLE,
    RESET_AUTH_TYPE,
    CLOSE_2FA_ENABLE_FLOW
} from "./authActions";
import { get2FaEnableFlowSeenStatus } from "./functions";
import {
    AuthAction,
    AuthInitialState,
    Cookies,
    Toggle2FaEnableState
} from "./types";

import {
    ADMIN_USER,
    CARRIER_COMPANY,
    COOKIE_NAMES,
    SYSTEM_USER,
    SUPPORT_USER,
    ADMIN_MANAGER_USER,
    LAST_2FA_ENABLE_SUGGESTION
} from "../../shared";

const AuthReducer = (
    state: AuthInitialState,
    action: AuthAction
): AuthInitialState => {
    switch (action.type) {
        case TOGGLE_2FA_VERIFICATION: {
            const { authType, value } = action.payload;

            return {
                ...state,
                errorMessage: null,
                authType,
                is2FaVerification: value,
                isLoading: false
            };
        }
        case TOGGLE_2FA_ENABLE: {
            const { isSupportUser, user } = state as Toggle2FaEnableState;
            const { value: isEnabled, authType } = action.payload;

            const is2FaEnableFlowSeen = get2FaEnableFlowSeenStatus(
                user.created_at,
                isSupportUser,
                isEnabled
            );

            return {
                ...state,
                user: {
                    ...user,
                    two_factor_auth_enabled: isEnabled,
                    two_factor_auth_type: authType
                },
                authType,
                is2FaEnableFlowSeen
            };
        }
        case CLOSE_2FA_ENABLE_FLOW: {
            const now = moment().format("YYYY-MM-DD HH:mm:ss");
            localStorage.setItem(LAST_2FA_ENABLE_SUGGESTION, now);

            return {
                ...state,
                is2FaEnableFlowSeen: true
            };
        }
        case RESET_AUTH_TYPE: {
            const { user } = state;

            return {
                ...state,
                user: user
                    ? {
                          ...user,
                          two_factor_auth_type: null
                      }
                    : null,
                authType: null
            };
        }
        case LOGIN_SUCCESS:
        case SET_USER: {
            const { user, companyLevelSettings } = action.payload;

            const { company_id, created_at, role, two_factor_auth_enabled } =
                user;

            const isSupportUser = role >= SUPPORT_USER;

            const is2FaEnableFlowSeen = get2FaEnableFlowSeenStatus(
                created_at,
                isSupportUser,
                two_factor_auth_enabled
            );

            return {
                ...state,
                user,
                allCompanyLevelSettings: companyLevelSettings,
                errorMessage: null,
                authType: user.two_factor_auth_type,
                is2FaEnableFlowSeen,
                is2FaVerification: false,
                isAuthenticated: true,
                isSupportUser,
                isAdminUser: role >= ADMIN_USER,
                isAdminManagerUser: role >= ADMIN_MANAGER_USER,
                isSystemUser: role >= SYSTEM_USER,
                isCarrierUser: company_id === CARRIER_COMPANY,
                isLoading: false,
                isAuthLoading: false
            };
        }
        case AUTH_ERROR: {
            return {
                ...state,
                user: null,
                allCompanyLevelSettings: null,
                errorMessage: action.payload || null,
                authType: null,
                is2FaVerification: false,
                isAuthenticated: false,
                isSupportUser: false,
                isAdminUser: false,
                isAdminManagerUser: false,
                isSystemUser: false,
                isCarrierUser: false,
                isLoading: false,
                isAuthLoading: false
            };
        }
        case REMOVE_ERROR_MESSAGE: {
            return {
                ...state,
                errorMessage: null
            };
        }
        case UPDATE_USER_COMPANY: {
            return {
                ...state,
                user: action.payload
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case REMOVE_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SET_COOKIE_CONSENT: {
            const yearFromNowToExpire = new Date(
                new Date().setFullYear(new Date().getFullYear() + 1)
            );

            const { cookieName, cookieValue } = action.payload;

            const allCookies = { ...state.cookieConsent };

            const allCookieNames = Object.keys(allCookies) as COOKIE_NAMES[];

            const setCookies = () => {
                if (cookieName === COOKIE_NAMES.All) {
                    const allCookiesToSet = {} as Cookies;

                    allCookieNames.forEach(nameOfCookie => {
                        document.cookie = `${nameOfCookie}=${cookieValue}; expires=${yearFromNowToExpire}`;

                        allCookiesToSet[nameOfCookie as keyof Cookies] =
                            cookieValue.toString();
                    });

                    return allCookiesToSet;
                } else {
                    document.cookie = `${cookieName}=${cookieValue}; expires=${yearFromNowToExpire}`;
                }
            };

            return {
                ...state,
                cookieConsent: {
                    ...state.cookieConsent,
                    ...setCookies()
                }
            };
        }
        default:
            return state;
    }
};

export default AuthReducer;
