import { FormEvent } from "react";
import { Trans, useTranslation } from "react-i18next";

import { BorderFrame, Buttons, InnerBottom, InnerTop } from "../components";
import { SendOtpCodeProps } from "../types";

import { SendOtpCodeToEnable } from "../../../../Navbar/Controls/Settings/Security/TwoFactorAuthentication/EnableTwoFactorAuthentication/content";

import { ENABLE_AUTHENTICATION_STEPS } from "../../../../../shared";
import TextLink, {
    TEXT_LINK_SIZES
} from "../../../../DesignComponents/TextLink";

const SendOtpCode = ({
    secretKey,
    isLoading,
    error,
    otpCode,
    authType,
    setOtpCode,
    changeOtpCode,
    changeStep,
    sendOtpCodeSubmit,
    setError
}: SendOtpCodeProps) => {
    const { t } = useTranslation();

    const handleSecondaryButtonClick = () => {
        changeStep(ENABLE_AUTHENTICATION_STEPS.SelectMethod);
        setOtpCode("");
        setError(null);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        sendOtpCodeSubmit();
    };

    return (
        <form onSubmit={handleSubmit}>
            <BorderFrame>
                <InnerTop
                    title={
                        <Trans
                            i18nKey="Security##2FA##send otp code##title"
                            components={{
                                1: (
                                    <TextLink
                                        size={TEXT_LINK_SIZES.Normal}
                                        href=" https://www.microsoft.com/en-us/security/mobile-authenticator-app"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Microsoft Authenticator
                                    </TextLink>
                                ),
                                2: (
                                    <TextLink
                                        size={TEXT_LINK_SIZES.Normal}
                                        href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Google Authenticator
                                    </TextLink>
                                )
                            }}
                        />
                    }
                />

                <InnerBottom>
                    <SendOtpCodeToEnable
                        secretKey={secretKey}
                        error={error}
                        isLoading={isLoading}
                        changeCode={changeOtpCode}
                    />
                </InnerBottom>
            </BorderFrame>

            <Buttons
                isLoadingPrimary={isLoading}
                isPrimaryBtnDisabled={otpCode.length < 6 || authType === null}
                primaryBtnTitle={t("Button##verify")}
                secondaryBtnTitle={t("Button##back")}
                onSecondaryBtnClick={handleSecondaryButtonClick}
            />
        </form>
    );
};

export { SendOtpCode };
