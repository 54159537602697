/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { CanData } from "./types";

import CopyButton from "../../../DesignComponents/CopyButton";
import { BUTTON_SIZES } from "../../../DesignComponents/Button";

import TableContext from "../../../../context/table/tableContext";
import { renderCanOemFileVehicle } from "../../../../shared";

const Can = () => {
    const { detailsData } = useContext(TableContext);

    const { t } = useTranslation();

    const { can, can_package: assignedCanPackage }: CanData = detailsData;

    const getCanOemFileVehicleValue = () => {
        if (can) {
            const { vehicle, vehicle_id } = can;

            return renderCanOemFileVehicle(vehicle, vehicle_id, t);
        }

        return null;
    };

    const getCanOemFilePackageValue = () => {
        if (can) {
            const { can_package, can_package_id } = can;

            if (can_package) {
                return can_package.name;
            }

            if (can_package_id !== null) {
                return `${t("General##unknown")} (${t("General##id")}: ${can_package_id})`;
            }

            return null;
        }

        return null;
    };

    const canOemFileVehicle = getCanOemFileVehicleValue();
    const canOemFilePackage = getCanOemFilePackageValue();

    return (
        <>
            <div>{t("General##can")}</div>

            <div>
                {/* Firmware */}
                {can?.chip_version && (
                    <>
                        <span>{t("General##firmware")}:</span>

                        <span>
                            {can.chip_version}

                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={can.chip_version}
                            />
                        </span>
                    </>
                )}
                {/*  */}

                {/* Assigned package */}
                {assignedCanPackage && (
                    <>
                        <span>{t("General##assigned package")}:</span>

                        <span>
                            {assignedCanPackage.name}

                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={assignedCanPackage.name}
                            />
                        </span>
                    </>
                )}
                {/*  */}

                {/* CAN OEM file vehicle */}
                {canOemFileVehicle && (
                    <>
                        <span>{t("General##can oem file vehicle")}:</span>

                        <span
                            css={css({
                                "&::first-letter": {
                                    textTransform: "uppercase"
                                }
                            })}
                        >
                            {canOemFileVehicle}

                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={canOemFileVehicle}
                            />
                        </span>
                    </>
                )}
                {/*  */}

                {/* CAN OEM file package */}
                {canOemFilePackage && (
                    <>
                        <span>{t("General##can oem file package")}:</span>

                        <span>
                            {canOemFilePackage}

                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={canOemFilePackage}
                            />
                        </span>
                    </>
                )}
                {/*  */}
            </div>
        </>
    );
};

export default Can;
