/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import { InnerTopProps } from "../types";

import ThemeContext from "../../../../../context/theme/themeContext";

const InnerTop = ({ title, description }: InnerTopProps) => {
    const {
        colorsFacelift: { textDark }
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                padding: "24px",

                "& > p": {
                    color: textDark,
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    wordBreak: "break-word"
                }
            })}
        >
            <p
                css={css({
                    margin: "0",
                    fontWeight: "600",

                    "& > span": {
                        fontWeight: "700"
                    }
                })}
            >
                {title}
            </p>

            {description && (
                <p
                    css={css({
                        margin: "4px 0 0"
                    })}
                >
                    {description}
                </p>
            )}
        </div>
    );
};

export { InnerTop };
