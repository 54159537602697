/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import Grid from "@mui/material/Grid2";

import Pagination from "./Pagination";
import TableView from "./TableView";

import Search from "../Search";
import Filters from "../Filters";
import FilteredValues from "../Filters/components/FilteredValues";
import FilterSeparator from "../DesignComponents/FilterSeparator";
import { useTableFunctions } from "../Table/hooks";

import TableContext from "../../context/table/tableContext";
import { toolbarPadding, useMediaQueries } from "../../shared";

const Toolbar = () => {
    const {
        hideSearch,
        hideFilters,
        currentPage,
        rowsPerPage,
        totalRows,
        isInitialLoading,
        tableName
    } = useContext(TableContext);

    const { fromSm } = useMediaQueries();
    const { handlePageChange } = useTableFunctions();

    const hideFiltering = hideSearch && hideFilters;
    const showSeparator = fromSm && !hideSearch && !hideFilters;

    const justifyContent = hideFiltering ? "flex-end" : "space-between";

    return (
        <>
            <Grid
                container
                justifyContent={justifyContent}
                alignItems="center"
                rowSpacing="8px"
                css={css({
                    padding: toolbarPadding(tableName)
                })}
            >
                {!hideFiltering && (
                    <Grid
                        size={{ xs0: 12, md: 5, lg: 6 }}
                        css={css({
                            display: "flex",
                            alignItems: "center"
                        })}
                    >
                        {!hideSearch && <Search />}

                        {showSeparator && (
                            <FilterSeparator width={1} height={36} />
                        )}

                        {!hideFilters && <Filters />}
                    </Grid>
                )}

                <Grid
                    size={{ xs0: 12, md: 7, lg: 6 }}
                    css={css({
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",

                        "@media(max-width: 839px)": {
                            justifyContent: "space-between"
                        },

                        "@media(max-width: 599px)": {
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }
                    })}
                >
                    <Pagination
                        page={currentPage}
                        total={totalRows}
                        perPage={rowsPerPage}
                        onChange={handlePageChange}
                        isInitialLoading={isInitialLoading}
                    />

                    <TableView isInitialLoading={isInitialLoading} />
                </Grid>
            </Grid>

            <FilteredValues />
        </>
    );
};

export default Toolbar;
