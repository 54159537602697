/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import StatusBadge from "./StatusBadge";
import ActionBox from "./ActionBox";
import { SingleNotificationProps } from "./types";

import ListChoice from "../../../../../../components/DesignComponents/ListChoice";

const SingleNotification = ({ data }: SingleNotificationProps) => {
    const { t } = useTranslation();

    const {
        created_at,
        data: { messages },
        id,
        read_at,
        type
    } = data;

    const getStatus = () => {
        const separateWords = type.replace(/([a-z])([A-Z])/g, "$1 $2");
        const splitWords = separateWords.split(" ");

        return splitWords[splitWords.length - 1];
    };

    const status = getStatus();
    const typeSplit = type.split("\\");
    const typeName = typeSplit[typeSplit.length - 1];

    return (
        <ListChoice
            title={t(`Notifications##types##${typeName}`)}
            description={
                <>
                    {messages.map(message => (
                        <span key={message}>{message}</span>
                    ))}
                </>
            }
            iconLeft={<StatusBadge status={status} />}
            iconRight={<ActionBox id={id} date={created_at} />}
            selected={read_at === null}
            isNotClickable
            isNotification
            isNormalWhiteSpace
            css={css({
                userSelect: "auto"
            })}
        />
    );
};

export default SingleNotification;
