import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Loader from "./Loader";

import { VehicleDataProps } from "../../../types";

import {
    ENDPOINTS,
    renderCanOemFileVehicle,
    useApi,
    useIsMounted
} from "../../../../../../../../shared";

const VehicleData = ({ id }: VehicleDataProps) => {
    const { t } = useTranslation();
    const { getData, isCanceled } = useApi();
    const isMounted = useIsMounted();

    const [isLoading, setLoading] = useState(true);
    const [message, setMessage] = useState<string | null>(null);

    useEffect(() => {
        const getVehicleData = async () => {
            try {
                const { data } = await getData(
                    `${ENDPOINTS.CanAdapters}/vehicles/${id}`
                );

                const canOemFileVehicle = renderCanOemFileVehicle(data, id, t);

                setMessage(canOemFileVehicle);
            } catch (error) {
                if (isMounted && !isCanceled(error)) {
                    const canOemFileVehicle = renderCanOemFileVehicle(
                        null,
                        id,
                        t
                    );

                    setMessage(canOemFileVehicle);
                }
            }

            setLoading(false);
        };

        getVehicleData();

        // eslint-disable-next-line
    }, []);

    return isLoading ? (
        <Loader />
    ) : (
        <>
            <span>{t("Attributes##vehicle")}:</span>
            <span>{message}</span>
        </>
    );
};

export default VehicleData;
