import moment from "moment";

import { is2FaMandatory, LAST_2FA_ENABLE_SUGGESTION } from "../../shared";

const shouldHideReminder = (closeDate: string | null) => {
    const isValidDate =
        closeDate && moment(closeDate, "YYYY-MM-DD HH:mm:ss", true).isValid();

    if (!isValidDate) {
        return false;
    }

    const nextShowDate = moment(closeDate).add(14, "days");

    return moment().isBefore(nextShowDate);
};

const get2FaEnableFlowSeenStatus = (
    createdAt: string,
    isSupportUser: boolean,
    isEnabled: boolean
) => {
    if (isEnabled) {
        return true;
    }

    if (is2FaMandatory(isSupportUser, createdAt)) {
        return false;
    }

    const last2FaEnableSuggestionCloseDate = localStorage.getItem(
        LAST_2FA_ENABLE_SUGGESTION
    );

    return shouldHideReminder(last2FaEnableSuggestionCloseDate);
};

export { get2FaEnableFlowSeenStatus };
