import { RectangleLoader } from "../../../../../../../../shared";

const Loader = () => {
    return (
        <RectangleLoader
            width={208}
            height={16}
            customStyle={{ margin: "2px 0" }}
        />
    );
};

export default Loader;
