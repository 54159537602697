import { useContext, Children, cloneElement } from "react";
import MuiTabs, { TabsProps as MuiTabsProps } from "@mui/material/Tabs";

import SegmentedToggleButtons from "./SegmentedToggleButtons";
import { useStyleOptions } from "./hooks/useStyleOptions";
import { SegmentedToggleProps } from "./types";

import ThemeContext from "../../../context/theme/themeContext";

export { TAB_SIZES } from "./types/enums";

const SegmentedToggle = ({
    children,
    size,
    tabWidth,
    fullWidth,
    ...props
}: SegmentedToggleProps & Omit<MuiTabsProps, "children">) => {
    const {
        colorsFacelift: {
            gray100,
            gray200,
            gray300,
            gray700,
            textDark,
            textDarkDisabled,
            white
        }
    } = useContext(ThemeContext);

    const styleOptions = useStyleOptions();

    const padding = styleOptions[size].padding;
    const tabPadding = styleOptions[size].tabPadding;
    const scrollButtonLeftMargin = styleOptions[size].scrollButtonLeftMargin;
    const scrollButtonRightMargin = styleOptions[size].scrollButtonRightMargin;
    const columnGap = styleOptions[size].columnGap;
    const borderRadius = styleOptions[size].borderRadius;

    return (
        <MuiTabs
            {...props}
            variant="scrollable"
            allowScrollButtonsMobile
            ScrollButtonComponent={buttonProps =>
                SegmentedToggleButtons(
                    scrollButtonLeftMargin,
                    scrollButtonRightMargin,
                    buttonProps
                )
            }
            sx={{
                display: fullWidth ? "flex" : "inline-flex",
                minHeight: "unset",
                maxWidth: "100%",
                border: `1px solid ${gray200}`,
                background: gray100,
                padding: padding,
                borderRadius: borderRadius,

                "& .MuiTabs-scroller": {
                    display: "flex",
                    flex: fullWidth ? "1" : "unset"
                },

                "& .MuiTabs-flexContainer": {
                    display: fullWidth ? "grid" : "inline-grid",
                    gridAutoFlow: "column",
                    gridAutoColumns: fullWidth ? "1fr" : tabWidth || "auto",
                    gridColumnGap: columnGap,
                    flex: fullWidth ? "1" : "unset"
                },

                "& .MuiTabs-indicator": {
                    display: "none"
                }
            }}
        >
            {Children.map(children, child =>
                cloneElement(child, {
                    sx: {
                        display: "flex",
                        flexDirection: "row",
                        color: textDark,
                        background: gray100,
                        textTransform: "none",
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        padding: tabPadding,
                        minHeight: "unset",
                        borderRadius: "6px",
                        whiteSpace: "nowrap",
                        maxWidth: "unset",
                        minWidth: "unet",
                        transition:
                            "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

                        "& .MuiTab-icon": {
                            margin: "0 8px 0 0"
                        },

                        "&.Mui-selected": {
                            color: gray700,
                            background: white
                        },

                        "&.Mui-disabled": {
                            color: textDarkDisabled
                        },

                        "&:hover": {
                            background: gray200
                        },

                        "&:active": {
                            background: gray300
                        }
                    }
                })
            )}
        </MuiTabs>
    );
};

export default SegmentedToggle;
