import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import TransferDevicesContent from "./TransferDevicesContent/TransferDevicesContent";

import { useTokenValidation } from "../../hooks";
import {
    DeviceTransferModalProps,
    MODAL_WINDOW,
    TransferDevicesValidationProps
} from "../../types";

import TriSource from "../../../components/TriSource/TriSource";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../../DesignComponents/Button";

import TriSourceContext from "../../../../../../context/triSource/triSourceContext";
import TableContext from "../../../../../../context/table/tableContext";
import DropzoneContext from "../../../../../../context/dropzone/dropzoneContext";
import AlertContext from "../../../../../../context/alert/alertContext";
import {
    BackgroundActionData,
    ENDPOINTS,
    ID_TYPE,
    TRI_SOURCES,
    useApi,
    useMediaQueries
} from "../../../../../../shared";

const TransferDevices = ({
    isOpen,
    close,
    changeDialogWindow,
    modalCSS,
    removeResize
}: DeviceTransferModalProps &
    Omit<TransferDevicesValidationProps, "token">) => {
    const { rows, deselectAllRows, deleteMultipleRows } =
        useContext(TableContext);

    const { activeSource, sourcePayload, isSourceReady } =
        useContext(TriSourceContext);

    const { setBackgroundActionId } = useContext(AlertContext);
    const { columnData } = useContext(DropzoneContext);

    const { postData, handleResponse } = useApi();

    const { toMd } = useMediaQueries();

    const { t } = useTranslation();

    const [isSubmitLoading, setSubmitLoading] = useState(false);
    const [token, setToken] = useState("");

    const {
        clearTokenError,
        tokenLoading,
        validateToken,
        tokenError,
        validationData,
        isCompanyVisible
    } = useTokenValidation({ token, removeResize });

    const isReady =
        isSourceReady &&
        sourcePayload &&
        token.trim().length > 0 &&
        !tokenError;

    const handleToken = (data: string) => {
        data ? setToken(data) : setToken("");
    };

    const getFilteredDevices = () => {
        const imeiArray: number[] = [];

        rows.forEach((device: any) => {
            imeiArray.push(device[ID_TYPE.Imei]);
        });

        return imeiArray;
    };

    const getFormData = () => {
        const formData = new FormData();

        formData.append("source", activeSource);
        formData.append("file", sourcePayload as Blob);
        formData.append("data[token]", token);

        return formData;
    };

    const getPayloadData = () => {
        return {
            source: activeSource,
            [activeSource]: sourcePayload,
            data: { token }
        };
    };

    const submitTransferDevices = async () => {
        try {
            setSubmitLoading(true);

            const payloadData =
                activeSource === TRI_SOURCES.FromFile
                    ? getFormData()
                    : getPayloadData();

            const { data }: { data: BackgroundActionData } = await postData(
                `${ENDPOINTS.Devices}/transfer`,
                payloadData
            );

            if (!isCompanyVisible) {
                const finalImeiArray = (() => {
                    switch (activeSource) {
                        case TRI_SOURCES.Selected:
                            return sourcePayload;

                        case TRI_SOURCES.Filtered:
                            return getFilteredDevices();

                        default:
                            return columnData;
                    }
                })();

                finalImeiArray &&
                    deleteMultipleRows(finalImeiArray as number[]);
            }

            setBackgroundActionId(
                data,
                () => {
                    deselectAllRows();
                    close();
                },
                () => setSubmitLoading(false)
            );
        } catch (error) {
            handleResponse(error);
            setSubmitLoading(false);
        }
    };

    return (
        <TriSource
            title={t("Dialog##transfer devices")}
            description={t(
                !validationData
                    ? "Dialog##device transfer##transfer devices##device token"
                    : "Dialog##device transfer##transfer devices##confirmation"
            )}
            confirmationDescription={t(
                "Dialog##confirmations##transfer devices confirmation"
            )}
            hideTriSource={!!validationData}
            isTitleSeparator
            isActionsSeparator
            open={isOpen}
            close={close}
            isBulkActionLoading={isSubmitLoading}
            submit={submitTransferDevices}
            actions={
                <>
                    <Button
                        fullWidth
                        color={BUTTON_COLORS.Secondary}
                        size={BUTTON_SIZES.Normal}
                        variant={BUTTON_VARIANTS.TextOnly}
                        onClick={close}
                        disabled={isSubmitLoading}
                    >
                        {t("Button##cancel")}
                    </Button>

                    {!validationData ? (
                        <Button
                            data-testid="transfer-devices-next-button"
                            fullWidth
                            color={BUTTON_COLORS.Primary}
                            size={BUTTON_SIZES.Normal}
                            variant={BUTTON_VARIANTS.TextOnly}
                            onClick={validateToken}
                            isLoading={tokenLoading}
                            disabled={!isReady}
                        >
                            {t("Button##next")}
                        </Button>
                    ) : (
                        <Button
                            fullWidth
                            color={BUTTON_COLORS.Primary}
                            size={BUTTON_SIZES.Normal}
                            variant={BUTTON_VARIANTS.TextOnly}
                            type="submit"
                            onClick={validateToken}
                            isLoading={isSubmitLoading}
                            loaderText={t("Button##transferring")}
                        >
                            {t("Button##transfer")}
                        </Button>
                    )}
                </>
            }
            TransitionProps={{
                onExited: () => {
                    setSubmitLoading(false);
                    changeDialogWindow(MODAL_WINDOW.Main);
                    removeResize(false);
                }
            }}
            css={modalCSS}
            extendToMaxWidth={toMd}
        >
            <TransferDevicesContent
                setPayload={handleToken}
                tokenError={tokenError}
                clearError={clearTokenError}
                tokenCompany={validationData}
            />
        </TriSource>
    );
};
export default TransferDevices;
