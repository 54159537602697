/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import Grid from "@mui/material/Grid2";

import CompanyLevelWidget from "./CompanyLevelWidget";
import ResourceLimitsWidget from "./ResourceLimitsWidget";
import ResourcesWidget from "./ResourcesWidget";

import ThemeContext from "../../../context/theme/themeContext";
import ParamsContext from "../../../context/params/paramsContext";
import { isClientTypeCompany } from "../../../shared";

const RightSide = () => {
    const {
        colorsFacelift: { gray200 }
    } = useContext(ThemeContext);

    const { rootCompanyType } = useContext(ParamsContext);

    const isClientType = isClientTypeCompany(rootCompanyType);

    return (
        <>
            {isClientType ? (
                <Grid
                    container
                    css={css({
                        borderBottom: `1px solid ${gray200}`,

                        "@media (max-width: 1439px)": {
                            borderTop: `1px solid ${gray200}`
                        }
                    })}
                >
                    <Grid size={{ xs: 12, md: 6, lg: 12 }}>
                        <CompanyLevelWidget />
                    </Grid>

                    <Grid size={{ xs: 12, md: 6, lg: 12 }}>
                        <ResourceLimitsWidget />
                    </Grid>
                </Grid>
            ) : null}

            <ResourcesWidget />
        </>
    );
};

export default RightSide;
