/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState, MouseEvent, useContext } from "react";
import { useTranslation } from "react-i18next";

import { PASSWORD_TYPES } from "../types/enums";

import {
    TooltipGeneral,
    TOOLTIP_PLACEMENT
} from "../../DesignComponents/Tooltips";

import ThemeContext from "../../../context/theme/themeContext";

export const useTogglePswVisibility = (pswType: string) => {
    const {
        colorsFacelift: { gray500, gray600 }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    const [showPassword, setShowPassword] = useState({
        current: false,
        newPsw: false,
        repeat: false
    });

    const togglePswVisibility = (
        e: MouseEvent<HTMLSpanElement>,
        name: PASSWORD_TYPES
    ) => {
        e.preventDefault();
        setShowPassword({ ...showPassword, [name]: !showPassword[name] });
    };

    const PasswordVisibility = () => {
        return (
            <TooltipGeneral
                disableTouchListener
                placement={TOOLTIP_PLACEMENT.TopEnd}
                title={
                    showPassword[pswType as PASSWORD_TYPES]
                        ? t("Security##password##hide password")
                        : t("Security##password##show password")
                }
                css={css({
                    "& .MuiTooltip-tooltip": {
                        marginRight: "-6px"
                    }
                })}
            >
                <span
                    onMouseDown={e =>
                        togglePswVisibility(e, pswType as PASSWORD_TYPES)
                    }
                    className="change-psw-visibility"
                    css={css({
                        display: "flex",
                        cursor: "pointer",
                        color: gray500,
                        transition:
                            "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

                        "&:hover": {
                            color: gray600
                        }
                    })}
                >
                    {showPassword[pswType as PASSWORD_TYPES] ? (
                        <VisibilityOff />
                    ) : (
                        <Visibility />
                    )}
                </span>
            </TooltipGeneral>
        );
    };

    return { PasswordVisibility, showPassword };
};
