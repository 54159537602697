/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { SubtitleProps } from "./types";

import File from "../components/File";

import ThemeContext from "../../../context/theme/themeContext";
import { TaskGroupsLoader } from "../../../shared";

const Subtitle = ({ data, isLoading, file, fileName }: SubtitleProps) => {
    const {
        colorsFacelift: { textDark, textPlaceholder }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    const { id } = data;

    return (
        <div
            css={css({
                display: "flex",
                alignItems: "center",
                columnGap: "8px",
                rowGap: "4px",
                marginTop: "4px",

                "@media(max-width: 599px)": {
                    flexDirection: fileName && !file ? "column" : "row",
                    alignItems: fileName && !file ? "flex-start" : "center"
                }
            })}
        >
            {isLoading ? (
                <TaskGroupsLoader
                    height="20px"
                    firstWidth="52px"
                    secondWidth="200px"
                />
            ) : (
                <>
                    <div
                        css={css({
                            display: "flex",
                            columnGap: "8px",

                            div: {
                                display: "flex"
                            },

                            span: {
                                display: "inline-flex",
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                                whiteSpace: "nowrap"
                            },

                            "span:first-of-type": {
                                color: textPlaceholder,
                                paddingRight: isLoading ? 0 : "5px"
                            },

                            "span:last-of-type": {
                                color: textDark
                            }
                        })}
                    >
                        <div>
                            <span>{t("General##id")}:</span>
                            <span>{id}</span>
                        </div>
                    </div>

                    {fileName && <File data={file} isSubtitle />}
                </>
            )}
        </div>
    );
};

export default Subtitle;
