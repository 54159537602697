import { FormEvent, useContext } from "react";
import { Trans, useTranslation } from "react-i18next";

import { BorderFrame, Buttons, InnerBottom, InnerTop } from "../components";
import { SendEmailCodeProps } from "../types";

import { OtpInputField } from "../../../../Auth/shared";

import AuthContext from "../../../../../context/auth/authContext";
import { ENABLE_AUTHENTICATION_STEPS } from "../../../../../shared";

const SendEmailCode = ({
    isLoading,
    error,
    otpCode,
    authType,
    changeOtpCode,
    changeStep,
    sendOtpCodeSubmit,
    setError
}: SendEmailCodeProps) => {
    const { user } = useContext(AuthContext);

    const { t } = useTranslation();

    const handleSecondaryButtonClick = () => {
        changeStep(ENABLE_AUTHENTICATION_STEPS.GetEmailCode);
        setError(null);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        sendOtpCodeSubmit();
    };

    return (
        <form onSubmit={handleSubmit}>
            <BorderFrame>
                <InnerTop
                    title={
                        <Trans
                            i18nKey="Security##2FA##send email code##title"
                            values={{ email: user?.email }}
                            components={{
                                1: <span />
                            }}
                        />
                    }
                    description={t(
                        "Security##2FA##send email code##description"
                    )}
                />

                <InnerBottom>
                    <OtpInputField
                        isLoading={isLoading}
                        error={error}
                        onChange={changeOtpCode}
                        label={t("Password##verification code")}
                        isRequired
                    />
                </InnerBottom>
            </BorderFrame>

            <Buttons
                isLoadingPrimary={isLoading}
                isPrimaryBtnDisabled={otpCode.length < 6 || authType === null}
                primaryBtnTitle={t("Button##verify")}
                secondaryBtnTitle={t("Button##back")}
                onSecondaryBtnClick={handleSecondaryButtonClick}
            />
        </form>
    );
};

export { SendEmailCode };
