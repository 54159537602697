/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { forwardRef, useContext } from "react";

import ThemeContext from "../../../context/theme/themeContext";
import RoutesContext from "../../../context/routes/routesContext";

const MaintenanceBanner = forwardRef<HTMLElement>((_, ref) => {
    const {
        colorsFacelift: { orange200, textDark }
    } = useContext(ThemeContext);

    const { isMaintenanceBannerOpen } = useContext(RoutesContext);

    return isMaintenanceBannerOpen ? (
        <section
            ref={ref}
            css={css({
                "& > div": {
                    zIndex: "2"
                }
            })}
        >
            <div
                css={css({
                    position: "sticky",
                    padding: "8px 29px",
                    background: orange200,
                    textAlign: "center",
                    boxShadow: "none"
                })}
            >
                <div
                    css={css({
                        color: textDark,
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.1px"
                    })}
                >
                    Maintenance is planned on <b>December 11, 2024</b>, starting
                    at 06:00 UTC. Expected service unavailability -{" "}
                    <b>2 hours</b>.
                </div>
            </div>
        </section>
    ) : null;
});

export default MaintenanceBanner;
