/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import TextLink, {
    TEXT_LINK_SIZES
} from "../../../../../../../DesignComponents/TextLink";

const GetSecretKey = () => {
    return (
        <div
            css={css({
                display: "inline-flex",
                flexDirection: "column",

                "& > a:first-of-type": {
                    marginBottom: "4px"
                }
            })}
        >
            <TextLink
                size={TEXT_LINK_SIZES.Normal}
                href=" https://www.microsoft.com/en-us/security/mobile-authenticator-app"
                target="_blank"
                rel="noreferrer"
            >
                Microsoft Authenticator
            </TextLink>

            <TextLink
                size={TEXT_LINK_SIZES.Normal}
                href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid"
                target="_blank"
                rel="noreferrer"
            >
                Google Authenticator
            </TextLink>
        </div>
    );
};

export { GetSecretKey };
