import { useTranslation } from "react-i18next";

import VehicleData from "./VehicleData";

import { SingleAttributeProps, TASK_ATTRIBUTES } from "../../types";

import DateTime from "../../../../../../DateTime";

import { DateType, getFirstLetterUppercase } from "../../../../../../../shared";

const SingleAttribute = ({ keyName, value }: SingleAttributeProps) => {
    const { t } = useTranslation();

    const isOrder = keyName === TASK_ATTRIBUTES.Order;
    const isDate = keyName === TASK_ATTRIBUTES.Date;
    const isVehicleId = keyName === TASK_ATTRIBUTES.Vehicle_Id;

    const capitalizedKeyName = getFirstLetterUppercase(keyName);
    const name = t(`Attributes##${capitalizedKeyName}`);

    const getMessage = () => {
        if (isOrder) {
            return t(`Attributes##${value as string}`);
        }

        if (isDate) {
            return <DateTime date={value as DateType} format="L" />;
        }

        const renderValue = () => {
            if (Array.isArray(value)) {
                return value.length > 0 ? value.join(", ") : "—";
            }

            return value ?? "—";
        };

        return renderValue();
    };

    const message = getMessage();

    const renderVehicleData = isVehicleId && typeof value === "number";

    return renderVehicleData ? (
        <VehicleData id={value} />
    ) : (
        <>
            <span>{name}:</span>
            <span>{message}</span>
        </>
    );
};

export default SingleAttribute;
