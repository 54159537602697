/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import { SendOtpCodeToDisable } from "./content";
import { DISABLE_AUTHENTICATION_STEPS } from "./types";

import { sendCodeToEmail } from "../functions";

import Dialog from "../../../../../../DesignComponents/Dialog";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../../../DesignComponents/Button";

import AuthContext from "../../../../../../../context/auth/authContext";
import {
    DialogHandler,
    ENDPOINTS,
    isAuthenticatorType,
    maxDialogWidth,
    useApi,
    useMediaQueries
} from "../../../../../../../shared";

const DisableTwoFactorAuthentication = ({ isOpen, close }: DialogHandler) => {
    const { authType, user, resetAuthType, toggle2FaEnable } =
        useContext(AuthContext);

    const { t } = useTranslation();
    const { postData, handleResponse } = useApi();
    const { fromMd } = useMediaQueries();

    const [step, setStep] = useState(DISABLE_AUTHENTICATION_STEPS.Confirmation);
    const [isLoading, setLoading] = useState(false);
    const [otpCode, setOtpCode] = useState("");
    const [error, setError] = useState<string | null>(null);

    const changeStep = (newStep: DISABLE_AUTHENTICATION_STEPS) =>
        setStep(newStep);

    const changeOtpCode = (newValue: string) => {
        setError(null);
        setOtpCode(newValue);
    };

    const confirmationSubmit = () => {
        if (isAuthenticatorType(authType)) {
            changeStep(DISABLE_AUTHENTICATION_STEPS.SendOtpCode);
        } else {
            const successCallback = () =>
                changeStep(DISABLE_AUTHENTICATION_STEPS.SendEmailCode);

            sendCodeToEmail(
                setLoading,
                successCallback,
                postData,
                handleResponse
            );
        }
    };

    const sendOtpCodeSubmit = async () => {
        try {
            setLoading(true);

            const response = await postData(ENDPOINTS.TfaDisable, {
                otp_code: otpCode,
                two_factor_auth_type: authType
            });

            toggle2FaEnable(false, authType!);
            close();
            handleResponse(response);
        } catch (error) {
            const err = error as AxiosError;

            if (err.response?.status === 400) {
                setError(t("Password##incorrect code"));
            } else {
                handleResponse(error);
            }
        }

        setLoading(false);
    };

    const steps = {
        [DISABLE_AUTHENTICATION_STEPS.Confirmation]: {
            hasActionSeparator: false,
            title: t("Password##are you sure"),
            description: t("Password##disable description confirmation"),
            secondaryBtn: t("Button##cancel"),
            submitBtn: t("Button##yes"),
            isSubmitBtnDisabled: false,
            content: null,
            onSubmit: confirmationSubmit
        },
        [DISABLE_AUTHENTICATION_STEPS.SendOtpCode]: {
            hasActionSeparator: true,
            title: t("Password##disable two-factor authentication"),
            description: t("Password##disable description send code"),
            secondaryBtn: t("Button##close"),
            submitBtn: t("Button##disable"),
            isSubmitBtnDisabled: otpCode.length < 6,
            content: (
                <SendOtpCodeToDisable
                    authType={authType!}
                    error={error}
                    isLoading={isLoading}
                    changeCode={changeOtpCode}
                />
            ),
            onSubmit: sendOtpCodeSubmit
        },
        [DISABLE_AUTHENTICATION_STEPS.SendEmailCode]: {
            hasActionSeparator: true,
            title: t("Password##disable two-factor authentication"),
            description: (
                <Trans
                    i18nKey="Password##disable two-factor authentication description"
                    values={{ email: user?.email }}
                    components={{
                        1: (
                            <span
                                css={css({
                                    fontWeight: "700"
                                })}
                            />
                        )
                    }}
                />
            ),
            secondaryBtn: t("Button##close"),
            submitBtn: t("Button##disable"),
            isSubmitBtnDisabled: otpCode.length < 6,
            content: (
                <SendOtpCodeToDisable
                    authType={authType!}
                    error={error}
                    isLoading={isLoading}
                    changeCode={changeOtpCode}
                />
            ),
            onSubmit: sendOtpCodeSubmit
        }
    };

    const hasActionSeparator = steps[step].hasActionSeparator;
    const title = steps[step].title;
    const description = steps[step].description;
    const secondaryBtnName = steps[step].secondaryBtn;
    const submitBtnName = steps[step].submitBtn;
    const isDisabled = steps[step].isSubmitBtnDisabled;
    const content = steps[step].content;
    const onSubmit = steps[step].onSubmit;

    return (
        <Dialog
            open={isOpen}
            close={close}
            isTitleSeparator
            isActionsSeparator={hasActionSeparator}
            extendToMaxWidth
            title={title}
            description={description}
            submit={onSubmit}
            TransitionProps={{
                onExited: () => {
                    setStep(DISABLE_AUTHENTICATION_STEPS.Confirmation);
                    setOtpCode("");
                    setError(null);

                    if (user && !user.two_factor_auth_enabled) {
                        resetAuthType();
                    }
                }
            }}
            actions={
                <>
                    <Button
                        fullWidth
                        color={BUTTON_COLORS.Secondary}
                        size={BUTTON_SIZES.Normal}
                        variant={BUTTON_VARIANTS.TextOnly}
                        onClick={close}
                    >
                        {secondaryBtnName}
                    </Button>

                    {submitBtnName && (
                        <Button
                            fullWidth
                            color={BUTTON_COLORS.Primary}
                            size={BUTTON_SIZES.Normal}
                            variant={BUTTON_VARIANTS.TextOnly}
                            type="submit"
                            disabled={isDisabled}
                            isLoading={isLoading}
                        >
                            {submitBtnName}
                        </Button>
                    )}
                </>
            }
            css={css(fromMd && maxDialogWidth)}
        >
            {content}
        </Dialog>
    );
};

export default DisableTwoFactorAuthentication;
