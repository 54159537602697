/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { TitleProps } from "../types";

import ThemeContext from "../../../../../context/theme/themeContext";
import { ENABLE_AUTHENTICATION_STEPS } from "../../../../../shared";

const Title = ({ step }: TitleProps) => {
    const {
        colorsFacelift: { textDark }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    const renderText = () => {
        switch (step) {
            case ENABLE_AUTHENTICATION_STEPS.GetSecretKey:
            case ENABLE_AUTHENTICATION_STEPS.SendOtpCode:
                return t("Security##2FA##title##authentication app");
            case ENABLE_AUTHENTICATION_STEPS.GetEmailCode:
            case ENABLE_AUTHENTICATION_STEPS.SendEmailCode:
                return t("Security##2FA##title##authentication email");
            case ENABLE_AUTHENTICATION_STEPS.SuccessMessage:
                return t("Security##2FA##title##is enabled");
            default:
                return t("Security##2FA##title##protect your account");
        }
    };

    return (
        <div
            css={css({
                color: textDark,
                fontSize: "20px",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "0.15px",
                marginTop: "8px"
            })}
        >
            {renderText()}
        </div>
    );
};

export { Title };
