/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { Trans } from "react-i18next";
import { ForumOutlined, InfoOutlined } from "@mui/icons-material";

import IconLink from "./IconLink";

import TextLink, { TEXT_LINK_SIZES } from "../../DesignComponents/TextLink";
import ThemeContext from "../../../context/theme/themeContext";
import LogoIcon from "../../../assets/customIcons/contacts/LogoIcon";

const ForumIcon = () => <ForumOutlined />;
const WikiIcon = () => <InfoOutlined />;

const Contacts = () => {
    const {
        colorsFacelift: { textDark }
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                columnGap: "16px",
                marginTop: "16px"
            })}
        >
            <div
                css={css({
                    color: textDark,
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "16px",
                    letterSpacing: "0.4px",
                    maxWidth: "208px"
                })}
            >
                <Trans
                    i18nKey="Auth##privacy policy"
                    components={{
                        1: (
                            <TextLink
                                href="https://teltonika-iot-group.com/privacy-policy"
                                target="_blank"
                                rel="noreferrer"
                                size={TEXT_LINK_SIZES.Small}
                            >
                                Privacy policy
                            </TextLink>
                        )
                    }}
                />
            </div>

            <div
                css={css({
                    display: "flex",
                    alignItems: "center",

                    "& > a + a": {
                        marginLeft: "16px"
                    }
                })}
            >
                <IconLink
                    link="https://teltonika-gps.com/"
                    tooltip="teltonika telematics"
                    icon={LogoIcon}
                />

                <IconLink
                    link="https://community.teltonika-gps.com/"
                    tooltip="community forum"
                    icon={ForumIcon}
                />

                <IconLink
                    link="https://wiki.teltonika-gps.com/view/FOTA_WEB"
                    tooltip="wiki"
                    icon={WikiIcon}
                />
            </div>
        </div>
    );
};

export default Contacts;
