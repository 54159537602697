import { FormEvent } from "react";
import { useTranslation } from "react-i18next";

import { BorderFrame, Buttons, InnerTop } from "../components";
import { SuccessMessageProps } from "../types";

const SuccessMessage = ({ closeScreen }: SuccessMessageProps) => {
    const { t } = useTranslation();

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        closeScreen();
    };

    return (
        <form onSubmit={handleSubmit}>
            <BorderFrame>
                <InnerTop
                    title={t("Security##2FA##success message##title")}
                    description={t(
                        "Security##2FA##success message##description"
                    )}
                />
            </BorderFrame>

            <Buttons
                isPrimaryBtnDisabled={false}
                primaryBtnTitle={t("Button##open fota web")}
            />
        </form>
    );
};

export { SuccessMessage };
