import { useTranslation } from "react-i18next";

import { STRING_INPUT_MAX_LENGTH } from "../constants";

export const useStringInputValidation = (value: string, maxLength?: number) => {
    const { t } = useTranslation();

    const inputMaxLength = maxLength ?? STRING_INPUT_MAX_LENGTH;

    const isLengthValid = value.length <= inputMaxLength;

    const stringInputError = isLengthValid
        ? null
        : t("Dialog##input text too long", {
              maxLength: inputMaxLength
          });

    return {
        stringInputError
    };
};
