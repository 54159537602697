/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";

import { FrameProps } from "./types";

import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../DesignComponents/Tooltips";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../DesignComponents/Button";
import BannerMessage from "../DesignComponents/BannerMessage";

import ThemeContext from "../../context/theme/themeContext";
import DropzoneContext from "../../context/dropzone/dropzoneContext";
import { FILE_COUNT } from "../../shared";

const Frame = ({
    isDragged,
    supportedFormats,
    getRootProps,
    getInputProps,
    isMultiple,
    isDisabled,
    tooltipText,
    isUploaded
}: FrameProps) => {
    const { t } = useTranslation();

    const {
        colorsFacelift: {
            blue700,
            blue100,
            gray50,
            gray200,
            gray700,
            textDarkDisabled
        }
    } = useContext(ThemeContext);

    const { messages } = useContext(DropzoneContext);

    const bgColor = isDragged ? blue100 : gray50;
    const bgColorHover = isDisabled ? gray50 : blue100;
    const borderColor = isDragged ? blue700 : gray200;
    const borderColorHover = isDisabled ? gray200 : blue700;
    const textColor = isDisabled ? textDarkDisabled : gray700;

    const multipleCheck = isMultiple ? FILE_COUNT.Files : FILE_COUNT.File;

    const renderTitle = () => {
        if (isUploaded) {
            return t("Dialog##selected files");
        }

        return t(`Dropzone##add ${multipleCheck}`);
    };

    const getFileSelection = () => {
        if (supportedFormats.length === 0) {
            return <div>{t("Dropzone##select file type")}</div>;
        } else {
            return (
                <Fragment>
                    <div>
                        {t(`Dropzone##or drop ${multipleCheck} to upload`)}
                    </div>

                    <div>
                        {t("Dropzone##supported formats")}{" "}
                        {supportedFormats.join(", ")}
                    </div>
                </Fragment>
            );
        }
    };

    return (
        <Fragment>
            <div
                css={css({
                    color: gray700,
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    marginBottom: "4px"
                })}
            >
                {renderTitle()}
            </div>

            {messages?.map(({ status, title, description }) => (
                <BannerMessage
                    key={title}
                    status={status}
                    title={title}
                    description={description}
                    css={css({
                        marginBottom: "4px"
                    })}
                />
            ))}

            {!isUploaded ? (
                <TooltipGeneral
                    placement={TOOLTIP_PLACEMENT.Top}
                    title={tooltipText || ""}
                >
                    <div
                        {...getRootProps()}
                        css={css({
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "128px",
                            background: bgColor,
                            border: `2px dashed ${borderColor}`,
                            borderRadius: "8px",
                            padding: "22px 30px",
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            textAlign: "center",
                            boxSizing: "border-box",
                            cursor: isDisabled ? "auto" : "pointer",
                            transition: "all 0.3s ease-in-out",

                            "&:hover": {
                                background: bgColorHover,
                                border: `2px dashed ${borderColorHover}`
                            },

                            ".on-drag-enter-enter, .on-drag-leave-enter": {
                                opacity: "0"
                            },

                            ".on-drag-enter-enter-active, .on-drag-leave-enter-active":
                                {
                                    opacity: "1",
                                    transition: "opacity 300ms ease-in-out"
                                },

                            ".on-drag-enter-exit, .on-drag-leave-exit": {
                                opacity: "1"
                            },

                            ".on-drag-enter-exit-active, .on-drag-leave-exit-active":
                                {
                                    opacity: "0"
                                }
                        })}
                    >
                        <CSSTransition
                            in={isDragged}
                            unmountOnExit
                            timeout={{ enter: 300 }}
                            classNames="on-drag-enter"
                        >
                            <div
                                css={css({
                                    color: blue700,
                                    fontWeight: "600"
                                })}
                            >
                                {t(`Dropzone##drop ${multipleCheck} to upload`)}
                            </div>
                        </CSSTransition>

                        <CSSTransition
                            in={!isDragged}
                            unmountOnExit
                            timeout={{ enter: 300 }}
                            classNames="on-drag-leave"
                        >
                            <div>
                                <Button
                                    data-testid="select-file-button"
                                    color={BUTTON_COLORS.PrimarySubtle}
                                    size={BUTTON_SIZES.Small}
                                    variant={BUTTON_VARIANTS.TextOnly}
                                    disabled={isDisabled}
                                    css={css({
                                        marginBottom: "4px"
                                    })}
                                >
                                    {t(`Dropzone##select ${multipleCheck}`)}
                                </Button>

                                <div
                                    css={css({
                                        color: textColor,

                                        "& > div:first-of-type": {
                                            fontWeight: "600"
                                        }
                                    })}
                                >
                                    {getFileSelection()}
                                </div>
                            </div>
                        </CSSTransition>

                        <input {...getInputProps()} />
                    </div>
                </TooltipGeneral>
            ) : (
                <div></div>
            )}
        </Fragment>
    );
};

export default Frame;
