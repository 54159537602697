/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import { EditSectionProps } from "./types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";
import CopyButton from "../../DesignComponents/CopyButton";
import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../DesignComponents/BannerMessage";

const EditSection = ({
    children,
    isLoading,
    isEditable,
    sectionName,
    value,
    copiedValue,
    update,
    edit,
    cancel,
    hasBanner
}: EditSectionProps) => {
    const { t } = useTranslation();

    return (
        <>
            <span>{sectionName}:</span>

            <span>
                <form onSubmit={update}>
                    <div>{children}</div>

                    <div
                        css={css({
                            "& > button + button": {
                                marginLeft: "8px"
                            }
                        })}
                    >
                        <Button
                            disableRipple
                            color={BUTTON_COLORS.Secondary}
                            size={BUTTON_SIZES.Tiny}
                            variant={BUTTON_VARIANTS.TextOnly}
                            isLoading={isLoading}
                            onClick={isEditable ? update : edit}
                        >
                            {isEditable ? t("Button##save") : t("Button##edit")}
                        </Button>

                        {isEditable && (
                            <Button
                                disableRipple
                                color={BUTTON_COLORS.Secondary}
                                size={BUTTON_SIZES.Tiny}
                                variant={BUTTON_VARIANTS.TextOnly}
                                disabled={isLoading}
                                onClick={cancel}
                            >
                                {t("Button##cancel")}
                            </Button>
                        )}

                        {value && !isEditable && copiedValue && (
                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={copiedValue}
                            />
                        )}
                    </div>

                    {isEditable && hasBanner && (
                        <BannerMessage
                            status={BANNER_MESSAGE_STATUSES.Info}
                            title={t("Table##edit group banner")}
                        />
                    )}
                </form>
            </span>
        </>
    );
};

export default EditSection;
