/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";

import BackToSignIn from "./BackToSignIn";
import ContactUs from "./ContactUs";
import ForgotPassword from "./ForgotPassword";
import { useLogin, useTwoFactorVerification } from "./hooks";

import Redirection from "../Redirection/Redirection";
import AuthContainer from "../AuthContainer";
import { OtpInputField } from "../shared";
import { INPUT_FIELD } from "../types";

import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../DesignComponents/BannerMessage";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../DesignComponents/InputField";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";

import AuthContext from "../../../context/auth/authContext";
import { isAuthenticatorType } from "../../../shared";

const Login = () => {
    const { is2FaVerification, isLoading, authType } = useContext(AuthContext);

    const { t } = useTranslation();

    const { otpCode, twoFaError, handleOtpChange, handle2FaSubmit } =
        useTwoFactorVerification();

    const {
        errorMessage,
        isEmailValid,
        emailError,
        passwordError,
        showPassword,
        handleLoginSubmit,
        validateEmail,
        handleLoginChange,
        PasswordVisibility
    } = useLogin();

    const getFormName = () => {
        if (is2FaVerification) {
            return isAuthenticatorType(authType)
                ? t("Security##2FA##title##authentication app")
                : t("Security##2FA##title##authentication email");
        }

        return t("Auth##sign in to fota");
    };

    return (
        <Redirection>
            <AuthContainer
                formName={getFormName()}
                secondaryText={
                    is2FaVerification ? (
                        <BackToSignIn
                            isAuthenticatorType={isAuthenticatorType(authType)}
                        />
                    ) : (
                        <ContactUs />
                    )
                }
            >
                <CSSTransition
                    in={Boolean(errorMessage)}
                    timeout={{ enter: 300 }}
                    classNames="auth-error-message"
                    unmountOnExit
                >
                    <BannerMessage
                        status={BANNER_MESSAGE_STATUSES.Critical}
                        title={t(`Flash##${errorMessage}`, {
                            defaultValue: errorMessage
                        })}
                        css={css({
                            marginBottom: "16px",

                            "&.auth-error-message-enter": {
                                opacity: "0"
                            },

                            "&.auth-error-message-enter-active": {
                                opacity: "1",
                                transition: "opacity 300ms ease-in"
                            }
                        })}
                    />
                </CSSTransition>

                <form
                    noValidate
                    onSubmit={
                        is2FaVerification ? handle2FaSubmit : handleLoginSubmit
                    }
                >
                    {is2FaVerification ? (
                        <>
                            <OtpInputField
                                isLoading={isLoading}
                                error={twoFaError}
                                onChange={handleOtpChange}
                                label={
                                    isAuthenticatorType(authType)
                                        ? undefined
                                        : t(
                                              "Auth##account verification##code sent to email"
                                          )
                                }
                            />

                            <Button
                                fullWidth
                                color={BUTTON_COLORS.Primary}
                                size={BUTTON_SIZES.Normal}
                                variant={BUTTON_VARIANTS.TextOnly}
                                type="submit"
                                disabled={otpCode.length < 6}
                                isLoading={isLoading}
                                css={css({
                                    marginTop: "16px"
                                })}
                            >
                                {t("Button##verify")}
                            </Button>
                        </>
                    ) : (
                        <>
                            <InputField
                                data-testid="login-email-input-field"
                                size={INPUT_FIELD_SIZES.Medium}
                                id="standard-error-helper-text"
                                fullWidth
                                name={INPUT_FIELD.Email}
                                onBlur={validateEmail}
                                disabled={isLoading}
                                errorText={isEmailValid ? "" : emailError}
                                onChange={handleLoginChange}
                                labelLeft={t("Dialog##email")}
                                placeholder={`${t("Dialog##example")} email@fota.com`}
                                customStyle={{ marginBottom: "16px" }}
                            />

                            <InputField
                                fullWidth
                                name={INPUT_FIELD.Password}
                                labelLeft={t("Auth##password")}
                                placeholder={t("General##enter here")}
                                size={INPUT_FIELD_SIZES.Medium}
                                type={
                                    showPassword.current ? "text" : "password"
                                }
                                disabled={isLoading}
                                onChange={handleLoginChange}
                                errorText={passwordError.password1}
                                iconRight={<PasswordVisibility />}
                            />

                            <ForgotPassword />

                            <Button
                                data-testid="sign-in-button"
                                fullWidth
                                color={BUTTON_COLORS.Primary}
                                size={BUTTON_SIZES.Normal}
                                variant={BUTTON_VARIANTS.TextOnly}
                                type="submit"
                                isLoading={isLoading}
                            >
                                {t("Auth##sign in")}
                            </Button>
                        </>
                    )}
                </form>
            </AuthContainer>
        </Redirection>
    );
};

export default Login;
