/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Icon, Title } from "./components";
import {
    GetEmailCode,
    SelectAuthenticationType,
    SendEmailCode,
    SendOtpCode,
    SuccessMessage
} from "./content";

import Logo from "../../../Logo";

import {
    ENABLE_AUTHENTICATION_STEPS,
    useEnableTwoFactorAuthentication
} from "../../../../shared";

const EnableTwoFactorAuthentication = () => {
    const {
        authType,
        error,
        isLoading,
        otpCode,
        secretKey,
        step,
        changeAuthenticationType,
        changeOtpCode,
        changeStep,
        getEmailCodeSubmit,
        getSecretKeySubmit,
        handleClose,
        sendOtpCodeSubmit,
        setError,
        setOtpCode
    } = useEnableTwoFactorAuthentication();

    const renderMainContent = () => {
        switch (step) {
            case ENABLE_AUTHENTICATION_STEPS.GetEmailCode:
                return (
                    <GetEmailCode
                        isLoading={isLoading}
                        changeStep={changeStep}
                        getEmailCodeSubmit={getEmailCodeSubmit}
                    />
                );
            case ENABLE_AUTHENTICATION_STEPS.SendOtpCode:
                return (
                    <SendOtpCode
                        secretKey={secretKey}
                        isLoading={isLoading}
                        error={error}
                        otpCode={otpCode}
                        authType={authType}
                        setOtpCode={setOtpCode}
                        changeOtpCode={changeOtpCode}
                        changeStep={changeStep}
                        sendOtpCodeSubmit={sendOtpCodeSubmit}
                        setError={setError}
                    />
                );
            case ENABLE_AUTHENTICATION_STEPS.SendEmailCode:
                return (
                    <SendEmailCode
                        isLoading={isLoading}
                        error={error}
                        otpCode={otpCode}
                        authType={authType}
                        changeOtpCode={changeOtpCode}
                        changeStep={changeStep}
                        sendOtpCodeSubmit={sendOtpCodeSubmit}
                        setError={setError}
                    />
                );
            case ENABLE_AUTHENTICATION_STEPS.SuccessMessage:
                return <SuccessMessage closeScreen={() => handleClose()} />;
            default:
                return (
                    <SelectAuthenticationType
                        isLoading={isLoading}
                        type={authType}
                        changeType={changeAuthenticationType}
                        changeStep={changeStep}
                        getSecretKeySubmit={getSecretKeySubmit}
                    />
                );
        }
    };

    return (
        <div
            css={css({
                display: "flex",
                flexDirection: "column"
            })}
        >
            <div
                css={css({
                    display: "flex",
                    padding: "16px 24px"
                })}
            >
                <Logo />
            </div>

            <div
                css={css({
                    alignSelf: "center",
                    maxWidth:
                        step === ENABLE_AUTHENTICATION_STEPS.SelectMethod
                            ? "684px"
                            : "448px",
                    margin: "64px 24px 24px 24px",

                    "@media (max-width: 1439px)": {
                        margin: "24px"
                    }
                })}
            >
                <div
                    css={css({
                        textAlign: "center",
                        marginBottom: "32px"
                    })}
                >
                    <Icon step={step} />
                    <Title step={step} />
                </div>

                {renderMainContent()}
            </div>
        </div>
    );
};

export default EnableTwoFactorAuthentication;
