/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { DeleteResourceDialogProps } from "../types/interfaces";

import DialogWithConfirmation from "../../../components/DialogWithConfirmation";

import { GROUP_ACTIONS } from "../../../../types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../../DesignComponents/Button";
import Tag, {
    TAG_COLORS,
    TAG_SIZES
} from "../../../../../DesignComponents/Tag";

import TableContext from "../../../../../../context/table/tableContext";
import AlertContext from "../../../../../../context/alert/alertContext";
import {
    maxDialogWidth,
    TRI_SOURCES,
    useApi,
    useFormattedNumber,
    useMediaQueries,
    useWindowLocation
} from "../../../../../../shared";

const DeleteResourceForSelection = ({
    isOpen,
    resourceAction,
    close
}: DeleteResourceDialogProps) => {
    const { setBackgroundActionId } = useContext(AlertContext);
    const { selectedRows, deselectAllRows } = useContext(TableContext);

    const { t } = useTranslation();
    const { bulkDeleteData, deleteData, handleResponse } = useApi();
    const { renderFormattedNumber } = useFormattedNumber();
    const { resource } = useWindowLocation();
    const { toMd, fromMd } = useMediaQueries();

    const [isLoading, setLoading] = useState(false);

    const handleCloseDeleteDialog = () => close(resourceAction);

    const isDeleteDeviceGroupsAction =
        resourceAction === GROUP_ACTIONS.DeleteDeviceGroups;

    const resourceCount = selectedRows?.length;
    const isSingle = resourceCount === 1 && !isDeleteDeviceGroupsAction;

    const getPayload = () => {
        return isSingle
            ? selectedRows[0]
            : {
                  source: TRI_SOURCES.Selected,
                  [TRI_SOURCES.Selected]: selectedRows
              };
    };

    const submitDeleteSelection = async () => {
        try {
            setLoading(true);

            const response = await (isSingle
                ? deleteData(resource, getPayload() as number)
                : bulkDeleteData(resource, getPayload()));

            if (isSingle) {
                deselectAllRows();
                handleCloseDeleteDialog();
                handleResponse(response);
            } else {
                setBackgroundActionId(
                    response.data,
                    () => {
                        deselectAllRows();
                        handleCloseDeleteDialog();
                    },
                    () => setLoading(false)
                );
            }
        } catch (error) {
            handleResponse(error);
            setLoading(false);
        }
    };

    const translatedResource = t(
        `Dialog##confirmations##delete resources##${resource}`
    );

    const description = t(
        "Dialog##confirmations##delete resource confirmation##first part",
        { resource: translatedResource }
    );

    const confirmationDescription = `
        ${description} 
        ${t(
            "Dialog##confirmations##delete resource confirmation##second part",
            {
                resource: translatedResource
            }
        )}`;

    const isBulkActionLoading = !isSingle && isLoading;

    return (
        <DialogWithConfirmation
            data-testid="delete-resource-for-selection-dialog"
            title={t(`Dialog##delete ${resource}`)}
            description={description}
            isActionsSeparator
            isTitleSeparator
            open={isOpen}
            count={resourceCount}
            source={TRI_SOURCES.Selected}
            confirmationDescription={confirmationDescription}
            submit={submitDeleteSelection}
            close={handleCloseDeleteDialog}
            isBulkActionLoading={isBulkActionLoading}
            css={css(fromMd && maxDialogWidth)}
            extendToMaxWidth={toMd}
            actions={
                <>
                    <Button
                        data-testid="cancel-delete-for-selection-button"
                        fullWidth
                        color={BUTTON_COLORS.Secondary}
                        size={BUTTON_SIZES.Normal}
                        variant={BUTTON_VARIANTS.TextOnly}
                        onClick={handleCloseDeleteDialog}
                        disabled={isBulkActionLoading}
                    >
                        {t("Button##cancel")}
                    </Button>

                    <Button
                        data-testid="delete-resource-for-selection-button"
                        fullWidth
                        color={BUTTON_COLORS.Primary}
                        size={BUTTON_SIZES.Normal}
                        variant={BUTTON_VARIANTS.TextOnly}
                        type="submit"
                        isLoading={isLoading}
                        loaderText={
                            !isSingle ? t("Button##deleting") : undefined
                        }
                    >
                        {t("Button##delete")}
                    </Button>
                </>
            }
            TransitionProps={{
                onExited: () => setLoading(false)
            }}
        >
            <Tag
                data-testid="number-of-selected-items-for-selection"
                size={TAG_SIZES.Medium}
                color={TAG_COLORS.Primary}
                title={`${t(
                    `Dialog##selected ${resource}`
                )}: ${renderFormattedNumber(resourceCount)}`}
            />
        </DialogWithConfirmation>
    );
};

export default DeleteResourceForSelection;
