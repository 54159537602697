/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import Grid from "@mui/material/Grid2";

import LeftSide from "./LeftSide";
import RightSide from "./RightSide";

import ActionBar from "../Actions/ActionBar";
import MainContainer from "../MainContainer";

import ThemeContext from "../../context/theme/themeContext";
import { useMediaQueries } from "../../shared";

const Dashboard = () => {
    const {
        colorsFacelift: { gray200 }
    } = useContext(ThemeContext);

    const { toLg } = useMediaQueries();

    return (
        <MainContainer>
            <ActionBar name="dashboard" />

            <section className="remaining-height">
                <Grid
                    container
                    css={css({
                        flex: 1,
                        flexDirection: toLg ? "column" : "row"
                    })}
                >
                    <Grid size={{ xs: 12, lg: 8 }}>
                        <LeftSide />
                    </Grid>

                    <Grid
                        size={{ xs: 12, lg: 4 }}
                        css={css({
                            borderLeft: `1px ${gray200} solid`
                        })}
                    >
                        <RightSide />
                    </Grid>
                </Grid>
            </section>
        </MainContainer>
    );
};

export default Dashboard;
