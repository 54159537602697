/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import { ContentBoxProps } from "./types";

import TextLink, {
    TEXT_LINK_SIZES
} from "../../../../../DesignComponents/TextLink";
import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../../../../DesignComponents/Tooltips";

import ThemeContext from "../../../../../../context/theme/themeContext";
import InfoIcon from "../../../../../../assets/customIcons/status/InfoIcon";

const ContentBox = ({
    title,
    icon,
    text,
    margin,
    linkText,
    linkOnClick,
    description
}: ContentBoxProps) => {
    const {
        colorsFacelift: { blue700, gray300, textDark }
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                margin
            })}
        >
            <div
                css={css({
                    marginBottom: "8px",
                    display: "flex",
                    alignItems: "center"
                })}
            >
                <span
                    css={css({
                        color: textDark,
                        fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.15px"
                    })}
                >
                    {title}
                </span>

                {description && (
                    <TooltipGeneral
                        title={description}
                        placement={TOOLTIP_PLACEMENT.Top}
                    >
                        <span
                            css={css({
                                display: "flex",
                                marginLeft: "4px"
                            })}
                        >
                            <InfoIcon
                                css={css({
                                    fontSize: "16px",
                                    color: blue700
                                })}
                            />
                        </span>
                    </TooltipGeneral>
                )}
            </div>

            <div
                css={css({
                    border: `1px solid ${gray300}`,
                    borderRadius: "8px",
                    padding: "17px 23px",
                    color: textDark,
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "20px",
                    letterSpacing: "0.1px"
                })}
            >
                <div
                    css={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        columnGap: "4px"
                    })}
                >
                    <div
                        css={css({
                            display: "flex",
                            alignItems: "center",
                            columnGap: "6px",

                            "& svg": {
                                alignSelf: "flex-start",
                                color: textDark,
                                margin: "-2px -2px -2px 0"
                            }
                        })}
                    >
                        {icon}
                        {text}
                    </div>

                    <TextLink
                        size={TEXT_LINK_SIZES.Normal}
                        onClick={linkOnClick}
                        css={css({
                            alignSelf: "flex-start"
                        })}
                    >
                        {linkText}
                    </TextLink>
                </div>
            </div>
        </div>
    );
};

export default ContentBox;
