/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import SingleDataItem from "../TaskGeneralData/SingleDataItem";
import { TaskFileProps } from "../types";

import File from "../../../../../TableDetails/components/File";

const TaskFile = ({ id, file }: TaskFileProps) => {
    const { t } = useTranslation();

    return (
        <>
            <h4
                css={css({
                    textTransform: "uppercase",
                    fontWeight: "700",
                    letterSpacing: "0.15px",
                    lineHeight: "20px",
                    marginTop: "24px",
                    marginBottom: "8px"
                })}
            >
                {t("General##file")}
            </h4>

            <File data={file} />

            <SingleDataItem
                name={t("Table##file id")}
                data={id}
                css={css({
                    marginBottom: "0"
                })}
            />
        </>
    );
};

export default TaskFile;
