/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { TaskGroupsLoaderProps } from "./types";

import { RectangleLoader } from "../RectangleLoader";

export const TaskGroupsLoader = ({
    height,
    firstWidth,
    secondWidth
}: TaskGroupsLoaderProps) => {
    return (
        <div
            css={css({
                display: "flex",
                columnGap: "16px",

                "& > span": {
                    borderRadius: "46px"
                }
            })}
        >
            <span>
                <RectangleLoader width={firstWidth} height={height} />
            </span>

            <span>
                <RectangleLoader width={secondWidth} height={height} />
            </span>
        </div>
    );
};
