/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { IconProps } from "../types";

import TwoFactorEnabledIcon from "../../../../../assets/customIcons/security/TwoFactorEnabledIcon";
import TwoFactorDisabledIcon from "../../../../../assets/customIcons/security/TwoFactorDisabledIcon";
import { ENABLE_AUTHENTICATION_STEPS } from "../../../../../shared";

const Icon = ({ step }: IconProps) => {
    const isSelectMethodStep =
        step === ENABLE_AUTHENTICATION_STEPS.SelectMethod;

    const isSuccessMessageStep =
        step === ENABLE_AUTHENTICATION_STEPS.SuccessMessage;

    return (
        <div
            css={css({
                "& > svg": {
                    fontSize: isSelectMethodStep ? "200px" : "144px",

                    "@media (max-width: 839px)": {
                        fontSize: "144px"
                    }
                }
            })}
        >
            {isSuccessMessageStep ? (
                <TwoFactorEnabledIcon />
            ) : (
                <TwoFactorDisabledIcon />
            )}
        </div>
    );
};

export { Icon };
