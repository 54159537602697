import Grid from "@mui/material/Grid2";

import General from "./General";
import OtherInfo from "./OtherInfo";

import DetailsContainer from "../../components/DetailsContainer";

const Details = () => {
    return (
        <DetailsContainer>
            <Grid size={{ xs0: 12, md: 6, lg: 4 }}>
                <General />
            </Grid>

            <Grid size={{ xs0: 12, md: 6, lg: 4 }}>
                <OtherInfo />
            </Grid>
        </DetailsContainer>
    );
};

export default Details;
