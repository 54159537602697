import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const TwoFactorEnabledIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg
                width="200"
                height="200"
                viewBox="0 0 200 200"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="200" height="200" fill="white" />

                <path
                    d="M139.869 30.5279C127.914 30.5279 116.736 27.1984 107.192 21.443L106.97 21.3161C102.72 18.7476 97.4248 18.8586 93.2074 21.4747C92.9537 21.6332 92.7317 21.7759 92.5098 21.9028C83.1394 27.3886 72.2312 30.5279 60.5936 30.5279C58.4373 30.5279 56.3127 30.4169 54.2199 30.2108C44.8971 29.2754 36.811 36.6162 36.811 45.9866V81.0103C36.811 119.173 58.3739 154.054 92.5098 171.13L100.231 174.999L107.192 171.527L107.953 171.146C142.088 154.086 163.651 119.189 163.651 81.0262V46.0024C163.651 36.6321 155.565 29.2912 146.242 30.2425C144.15 30.4486 142.009 30.5596 139.869 30.5596V30.5279Z"
                    fill="url(#paint0_linear_10494_97)"
                />

                <path
                    d="M74.0801 99.8044L90.1254 115.834L126.56 79.399"
                    stroke="white"
                    strokeWidth="13.3667"
                    strokeMiterlimit="10"
                />

                <defs>
                    <linearGradient
                        id="paint0_linear_10494_97"
                        x1="100.231"
                        y1="19.4499"
                        x2="100.231"
                        y2="174.999"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C5E2FF" />
                        <stop offset="1" stopColor="#70B8FF" />
                    </linearGradient>
                </defs>
            </svg>
        </SvgIcon>
    );
};

export default TwoFactorEnabledIcon;
