import { useContext, useState, useEffect, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

import { InputName } from "./generalReducer";
import EditComponent from "./EditDropdown";
import { getFilteredValues } from "./functions";

import { EDIT_TYPE } from "../../types/enums";

import EditSection from "../../../EditSection";

import ParamsContext from "../../../../../context/params/paramsContext";
import TableContext from "../../../../../context/table/tableContext";
import { FILTER_NAMES, Query, ENDPOINTS, useApi } from "../../../../../shared";

interface GroupProps {
    isEditable: boolean;
    value: Query | null;
    toggleEditable: (name: InputName, value: boolean) => void;
    changeValue: (
        name: InputName,
        newValue: Query | null,
        isUpdate?: boolean
    ) => void;
}

const Group = ({
    isEditable,
    value,
    toggleEditable,
    changeValue
}: GroupProps) => {
    const { queryParams, updateFilterParams } = useContext(ParamsContext);
    const { detailsData } = useContext(TableContext);

    const { imei, company_id, group_id, group_name } = detailsData;

    const { t } = useTranslation();
    const { updateData, handleResponse } = useApi();

    const [isLoading, setLoading] = useState(false);
    const [resource, setResource] = useState<string>(ENDPOINTS.Groups);

    useEffect(() => {
        toggleEditable(EDIT_TYPE.Group, false);

        changeValue(
            EDIT_TYPE.Group,
            group_id !== null ? { id: group_id, name: group_name } : null
        );

        // eslint-disable-next-line
    }, [imei, group_name, group_id]);

    useEffect(() => {
        const updatedResource =
            company_id !== null
                ? `${ENDPOINTS.Groups}?company_id=${company_id}`
                : ENDPOINTS.Groups;

        setResource(updatedResource);
    }, [company_id]);

    const edit = () => toggleEditable(EDIT_TYPE.Group, true);
    const cancel = () => toggleEditable(EDIT_TYPE.Group, false);

    const update = async (e: SyntheticEvent) => {
        e.preventDefault();

        try {
            setLoading(true);

            const response = await updateData(ENDPOINTS.Devices, imei, {
                group_id: value ? value.id : null
            });

            changeValue(EDIT_TYPE.Group, value, true);
            handleResponse(response);
        } catch (error) {
            handleResponse(error);
        }

        setLoading(false);
    };

    const handleChange = (groupValue: Query | null) =>
        changeValue(EDIT_TYPE.Group, groupValue);

    const filterDevices = () => {
        const groupId: number | null = detailsData.group_id;
        const groupName: string | null = detailsData.group_name;

        if (groupId !== null && groupName !== null) {
            const rootCompanyId = queryParams[FILTER_NAMES.RootCompany];

            const filteredValues = getFilteredValues(
                FILTER_NAMES.GroupId,
                groupId,
                groupName
            );

            updateFilterParams(
                {
                    [FILTER_NAMES.RootCompany]: rootCompanyId,
                    [FILTER_NAMES.GroupId]: String(groupId)
                },
                filteredValues
            );
        }
    };

    return (
        <EditSection
            sectionName={t("Table##group")}
            update={update}
            isLoading={isLoading}
            isEditable={isEditable}
            edit={edit}
            cancel={cancel}
            value={value}
            copiedValue={value?.name}
            hasBanner
        >
            <EditComponent
                isEditable={isEditable}
                value={value}
                resource={resource}
                handleChange={handleChange}
                onClick={filterDevices}
            />
        </EditSection>
    );
};

export default Group;
