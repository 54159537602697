import { FormEvent, useContext } from "react";
import { Trans, useTranslation } from "react-i18next";

import { BorderFrame, Buttons, InnerTop } from "../components";
import { GetEmailCodeProps } from "../types";

import AuthContext from "../../../../../context/auth/authContext";
import { ENABLE_AUTHENTICATION_STEPS } from "../../../../../shared";

const GetEmailCode = ({
    isLoading,
    changeStep,
    getEmailCodeSubmit
}: GetEmailCodeProps) => {
    const { user } = useContext(AuthContext);

    const { t } = useTranslation();

    const handleSecondaryButtonClick = () =>
        changeStep(ENABLE_AUTHENTICATION_STEPS.SelectMethod);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        getEmailCodeSubmit();
    };

    return (
        <form onSubmit={handleSubmit}>
            <BorderFrame>
                <InnerTop
                    title={
                        <Trans
                            i18nKey="Security##2FA##get email code##title"
                            values={{ email: user?.email }}
                            components={{
                                1: <span />
                            }}
                        />
                    }
                    description={t(
                        "Security##2FA##get email code##description"
                    )}
                />
            </BorderFrame>

            <Buttons
                isLoadingPrimary={isLoading}
                isPrimaryBtnDisabled={false}
                primaryBtnTitle={t("Button##send code")}
                secondaryBtnTitle={t("Button##back")}
                onSecondaryBtnClick={handleSecondaryButtonClick}
            />
        </form>
    );
};

export { GetEmailCode };
