/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import Grid from "@mui/material/Grid2";

import { DetailsContainerProps } from "./types";

import ThemeContext from "../../../../context/theme/themeContext";

const DetailsContainer = ({ children }: DetailsContainerProps) => {
    const {
        colorsFacelift: { gray200, gray700, textDark }
    } = useContext(ThemeContext);

    return (
        <Grid
            container
            css={css({
                marginTop: "8px",
                paddingBottom: "12px",

                "& > div": {
                    padding: "16px 24px",
                    fontSize: "14px",
                    letterSpacing: "0.1px",

                    "& > div:first-of-type": {
                        color: textDark,
                        fontWeight: "700",
                        lineHeight: "20px"
                    },

                    "& > div:last-of-type": {
                        display: "inline-grid",
                        gridTemplateColumns: "auto auto",
                        gridColumnGap: "24px",
                        gridRowGap: "16px",
                        wordBreak: "break-word",
                        fontWeight: "600",
                        marginTop: "16px",

                        "& > span": {
                            display: "flex",
                            lineHeight: "20px",

                            "& > form": {
                                display: "inherit",
                                flexWrap: "wrap",
                                alignItems: "center",
                                gap: "8px"
                            },

                            "& form > button, & > button": {
                                alignSelf: "flex-start",
                                marginLeft: "8px"
                            }
                        },

                        "& > span:nth-of-type(odd)": {
                            color: gray700,
                            padding: "2px 0"
                        },

                        "& > span:nth-of-type(even)": {
                            color: textDark,
                            alignItems: "center"
                        },

                        "@media (max-width: 599px)": {
                            display: "block",

                            "& > span:nth-of-type(2n + 1)": {
                                marginTop: "16px"
                            }
                        },

                        "@media (min-width: 840px) and (max-width: 979px)": {
                            display: "block",

                            "& > span:nth-of-type(2n + 1)": {
                                marginTop: "16px"
                            }
                        }
                    }
                },

                "& > div:nth-of-type(1)": {
                    border: `1px solid ${gray200}`
                },

                "& > div:nth-of-type(2), & > div:nth-of-type(3)": {
                    borderTop: `1px solid ${gray200}`,
                    borderRight: `1px solid ${gray200}`,
                    borderBottom: `1px solid ${gray200}`
                },

                "& > div:nth-of-type(4)": {
                    borderLeft: `1px solid ${gray200}`,
                    borderRight: `1px solid ${gray200}`,
                    borderBottom: `1px solid ${gray200}`
                },

                "& > div:nth-of-type(5), & > div:nth-of-type(6)": {
                    borderRight: `1px solid ${gray200}`,
                    borderBottom: `1px solid ${gray200}`
                },

                "@media (max-width:1439px)": {
                    "& > div:nth-of-type(3)": {
                        borderTop: "none",
                        borderLeft: `1px solid ${gray200}`
                    },

                    "& > div:nth-of-type(4)": {
                        borderLeft: "none"
                    },

                    "& > div:nth-of-type(5)": {
                        borderLeft: `1px solid ${gray200}`
                    }
                },

                "@media (max-width:839px)": {
                    "& > div:nth-of-type(2)": {
                        borderTop: "none",
                        borderLeft: `1px solid ${gray200}`
                    },

                    "& > div:nth-of-type(4), & > div:nth-of-type(6)": {
                        borderLeft: `1px solid ${gray200}`
                    }
                }
            })}
        >
            {children}
        </Grid>
    );
};

export default DetailsContainer;
