/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import {
    ArrowBackRounded,
    DeleteOutlineRounded,
    ExpandMoreRounded
} from "@mui/icons-material";

import { ToolbarProps } from "./types";

import { useDialog } from "../ActionBar/hooks";
import CancelTasksForSelection from "../dialogs/Tasks/CancelTask/FromSelection/CancelTasksForSelection";
import DeleteResourceForSelection from "../dialogs/components/DeleteResource/DeleteResourceForSelection/DeleteResourceForSelection";
import { DEVICE_ACTIONS } from "../types";

import ListChoice from "../../DesignComponents/ListChoice";
import Menu, {
    centeredAnchorOrigin,
    centeredTransformOrigin
} from "../../DesignComponents/Menu";

import TableContext from "../../../context/table/tableContext";
import ThemeContext from "../../../context/theme/themeContext";
import CancelTaskIcon from "../../../assets/customIcons/actions/CancelTaskIcon";
import { MenuAnchorEl, useMediaQueries } from "../../../shared";

const SharedToolbar = ({ resourceAction, toggleActiveHead }: ToolbarProps) => {
    const {
        colorsFacelift: { blue700, white }
    } = useContext(ThemeContext);

    const { deselectAllRows } = useContext(TableContext);

    const { t } = useTranslation();
    const { isOpenDialog, openDialog, closeDialog } = useDialog();
    const { toMd, fromMd } = useMediaQueries();

    const [anchorEl, setAnchorEl] = useState<MenuAnchorEl>(null);

    const handleCloseMenu = () => setAnchorEl(null);

    const handleOpenMenu = (e: MouseEvent<HTMLDivElement>) =>
        setAnchorEl(e.currentTarget);

    const resetView = () => {
        toggleActiveHead(false);
        deselectAllRows();
    };

    const isCancelTasksAction = resourceAction === DEVICE_ACTIONS.CancelTasks;

    const getButtonText = isCancelTasksAction
        ? t("Button##cancel tasks")
        : t("Button##delete");

    const getIconLeft = isCancelTasksAction ? (
        <CancelTaskIcon />
    ) : (
        <DeleteOutlineRounded />
    );

    return (
        <>
            {fromMd && resourceAction !== DEVICE_ACTIONS.NoTableActions && (
                <div onClick={() => openDialog(resourceAction)}>
                    {getButtonText}
                </div>
            )}

            {toMd && (
                <>
                    {resourceAction !== DEVICE_ACTIONS.NoTableActions ? (
                        <>
                            <div onClick={handleOpenMenu}>
                                {t("Button##actions")}
                                <ExpandMoreRounded
                                    css={css({
                                        marginLeft: "4px"
                                    })}
                                />
                            </div>

                            <Menu
                                open={Boolean(anchorEl)}
                                onClose={handleCloseMenu}
                                anchorEl={anchorEl}
                                MenuListProps={{ disableListWrap: true }}
                                anchorOrigin={centeredAnchorOrigin}
                                transformOrigin={centeredTransformOrigin}
                                css={css({
                                    "& .MuiMenu-paper": {
                                        maxHeight: "337px",
                                        minWidth: "211px"
                                    }
                                })}
                            >
                                <ListChoice
                                    title={t("Button##default view")}
                                    iconLeft={<ArrowBackRounded />}
                                    onClick={resetView}
                                    css={css({
                                        background: white,
                                        color: blue700
                                    })}
                                />

                                <ListChoice
                                    title={getButtonText}
                                    iconLeft={getIconLeft}
                                    onClick={() => openDialog(resourceAction)}
                                />
                            </Menu>
                        </>
                    ) : (
                        <div onClick={resetView}>
                            {t("Button##default view")}
                        </div>
                    )}
                </>
            )}

            <DeleteResourceForSelection
                isOpen={
                    isCancelTasksAction
                        ? false
                        : isOpenDialog[
                              resourceAction as keyof typeof isOpenDialog
                          ]
                }
                close={closeDialog}
                resourceAction={resourceAction}
            />

            <CancelTasksForSelection
                isOpen={isOpenDialog[DEVICE_ACTIONS.CancelTasks]}
                close={closeDialog}
            />
        </>
    );
};

export default SharedToolbar;
