import MultipleFiles from "./MultipleFiles";
import SingleFile from "./SingleFile";
import { DropzoneProps } from "./types";

import { MAX_FILES_COUNT } from "../../shared";

const Dropzone = ({
    supportedFormats,
    isDisabled,
    countCsv,
    tooltipText,
    isMultiple,
    maxFileNameLength,
    maxFiles,
    isLoading,
    isUploaded
}: DropzoneProps) => {
    return isMultiple ? (
        <MultipleFiles
            supportedFormats={supportedFormats}
            isDisabled={isDisabled}
            maxFileNameLength={maxFileNameLength}
            maxFiles={maxFiles || MAX_FILES_COUNT}
            isLoading={isLoading}
            isUploaded={isUploaded}
        />
    ) : (
        <SingleFile
            supportedFormats={supportedFormats}
            isDisabled={isDisabled}
            maxFileNameLength={maxFileNameLength}
            countCsv={countCsv}
            tooltipText={tooltipText}
        />
    );
};

export default Dropzone;
