import { useContext, useEffect } from "react";
import { hotjar } from "react-hotjar";

import { isAnalyticsCookieAccepted, getEnvironment } from "..";

import AuthContext from "../../context/auth/authContext";

export const useHotjar = () => {
    const { cookieConsent } = useContext(AuthContext);

    const { isNotLocalEnv } = getEnvironment();

    const hotjarId = Number(process.env.REACT_APP_HOTJAR_ID);

    useEffect(() => {
        isNotLocalEnv &&
            isAnalyticsCookieAccepted(cookieConsent) &&
            hotjar.initialize({ id: hotjarId, sv: 6 });
    }, [hotjarId, isNotLocalEnv, cookieConsent]);
};
