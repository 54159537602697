/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { ButtonsProps } from "../types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../DesignComponents/Button";

const Buttons = ({
    isPrimaryBtnDisabled,
    primaryBtnTitle,
    isWhite,
    isLoadingPrimary,
    isLoadingSecondary,
    secondaryBtnTitle,
    onSecondaryBtnClick
}: ButtonsProps) => {
    return (
        <div
            css={css({
                display: "flex",
                justifyContent: "space-between",
                columnGap: "12px",
                marginTop: "16px"
            })}
        >
            {secondaryBtnTitle && (
                <Button
                    fullWidth={!isWhite}
                    variant={BUTTON_VARIANTS.TextOnly}
                    size={BUTTON_SIZES.Normal}
                    color={
                        isWhite ? BUTTON_COLORS.White : BUTTON_COLORS.Secondary
                    }
                    isLoading={isLoadingSecondary || false}
                    onClick={onSecondaryBtnClick}
                >
                    {secondaryBtnTitle}
                </Button>
            )}

            <Button
                type="submit"
                fullWidth={!isWhite}
                variant={BUTTON_VARIANTS.TextOnly}
                size={BUTTON_SIZES.Normal}
                color={BUTTON_COLORS.Primary}
                isLoading={isLoadingPrimary || false}
                disabled={isPrimaryBtnDisabled}
            >
                {primaryBtnTitle}
            </Button>
        </div>
    );
};

export { Buttons };
