import { ChangeEvent, useCallback, useState } from "react";

import DeviceRadioGroup from "../components/DeviceRadioGroup";
import { useTriSourceAction } from "../hooks";
import { EXPORT_TYPE } from "../types";

import TriSource from "../../components/TriSource/TriSource";
import DialogActions from "../../components/DialogActions";

import { DEVICE_ACTIONS, DialogProps } from "../../../types";

import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../DesignComponents/InputField";

import {
    BackgroundActionData,
    Column,
    ENDPOINTS,
    FILE_ID_TYPE,
    TRI_SOURCES,
    useStringInputValidation
} from "../../../../../shared";

const ExportDeviceData = ({ isOpen, close }: DialogProps) => {
    const {
        t,
        columns,
        activeSource,
        sourcePayload,
        isSourceReady,
        setBackgroundActionId,
        deselectAllRows,
        postData,
        handleResponse
    } = useTriSourceAction();

    const [isLoading, setIsLoading] = useState(false);
    const [target, setTarget] = useState(EXPORT_TYPE.CSV);
    const [deviceIdType, setDeviceIdType] = useState(FILE_ID_TYPE.Imei);
    const [description, setDescription] = useState("");

    const { stringInputError } = useStringInputValidation(description);

    const isReadyToConfirm =
        isSourceReady && sourcePayload !== null && stringInputError === null;

    const selectedColumns = columns
        .map((column: Column) => column.isActive === true && column.resource)
        .filter(item => item !== false);

    const handleCloseDialog = useCallback(() => {
        close(DEVICE_ACTIONS.ExportDeviceData);
    }, [close]);

    const handleTarget = (e: any) => {
        return setTarget(e.target.value);
    };

    const getExportData = useCallback(() => {
        const formData = new FormData();

        const activeColumns = () =>
            columns.map(
                (column: Column) =>
                    column.isActive === true &&
                    formData.append("columns[]", column.resource as string)
            );

        formData.append("source", activeSource);
        formData.append("id_type", deviceIdType);
        formData.append(activeSource, sourcePayload as Blob);
        formData.append("format", target);
        formData.append("description", description);
        activeColumns();

        return activeSource === TRI_SOURCES.FromFile
            ? formData
            : {
                  source: activeSource,
                  [activeSource]: sourcePayload,
                  format: target,
                  description,
                  columns: selectedColumns
              };
    }, [
        sourcePayload,
        target,
        activeSource,
        columns,
        selectedColumns,
        deviceIdType,
        description
    ]);

    const handleDeviceIdChange = (e: { target: { value: FILE_ID_TYPE } }) => {
        const { value } = e.target;
        setDeviceIdType(value);
    };

    const handleDescription = (e: ChangeEvent<HTMLInputElement>) =>
        setDescription(e.target.value);

    const handleSubmit = useCallback(async () => {
        if (isReadyToConfirm) {
            try {
                setIsLoading(true);

                const { data }: { data: BackgroundActionData } = await postData(
                    `${ENDPOINTS.Devices}/export`,
                    getExportData()
                );

                setBackgroundActionId(
                    data,
                    () => {
                        deselectAllRows();
                        handleCloseDialog();
                    },
                    () => setIsLoading(false)
                );
            } catch (error) {
                handleResponse(error);
                setIsLoading(false);
            }
        }
    }, [
        isReadyToConfirm,
        getExportData,
        deselectAllRows,
        handleCloseDialog,
        handleResponse,
        setBackgroundActionId,
        postData
    ]);

    return (
        <TriSource
            data-testid="export-device-data-dialog-with-tri-source"
            title={t("Dialog##export device data")}
            description={t("Dialog##export device description")}
            confirmationDescription={t(
                "Dialog##confirmations##export device data confirmation"
            )}
            close={handleCloseDialog}
            isBulkActionLoading={isLoading}
            open={isOpen}
            submit={handleSubmit}
            TransitionProps={{
                onExited: () => {
                    setIsLoading(false);
                    setDescription("");
                }
            }}
            actions={
                <DialogActions
                    onClose={handleCloseDialog}
                    isLoading={isLoading}
                    loaderText={t("Button##exporting")}
                    isCancelDisabled={isLoading}
                    isReadyToConfirm={isReadyToConfirm}
                />
            }
            deviceIdentifier={
                <DeviceRadioGroup
                    target={deviceIdType}
                    onChange={handleDeviceIdChange}
                    firstRadioLabel={t("Dialog##imei")}
                    firstRadioValue={FILE_ID_TYPE.Imei}
                    secondRadioLabel={t("Dialog##sn")}
                    secondRadioValue={FILE_ID_TYPE.SerialId}
                />
            }
        >
            <DeviceRadioGroup
                target={target}
                onChange={handleTarget}
                firstRadioLabel={EXPORT_TYPE.CSV.toUpperCase()}
                firstRadioValue={EXPORT_TYPE.CSV}
                secondRadioLabel={EXPORT_TYPE.XLSX.toUpperCase()}
                secondRadioValue={EXPORT_TYPE.XLSX}
            />

            <InputField
                fullWidth
                labelLeft={`${t("Dialog##description")} (${t("Dialog##optional")})`}
                placeholder={t("General##enter here")}
                size={INPUT_FIELD_SIZES.Medium}
                onChange={handleDescription}
                errorText={stringInputError}
                customStyle={{ marginBottom: "16px" }}
            />
        </TriSource>
    );
};

export default ExportDeviceData;
