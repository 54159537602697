/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import CookiesModal from "./CookiesModal";
import { CookieButtonType, CookiesMainDescription } from ".";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";

import AuthContext from "../../../context/auth/authContext";
import themeContext from "../../../context/theme/themeContext";
import { COOKIE_NAMES } from "../../../shared";

const CookiesBanner = () => {
    const { setCookieConsent, cookieConsent } = useContext(AuthContext);

    const {
        colorsFacelift: { gray200 }
    } = useContext(themeContext);

    const { t } = useTranslation();

    const [showBanner, setShowBanner] = useState<boolean>(
        cookieConsent.analytics !== "true" &&
            cookieConsent.analytics !== "false"
    );

    const [isOpenCookiesDialog, setIsOpenCookiesDialog] =
        useState<boolean>(false);

    const handleAcceptAll = () => {
        setCookieConsent(COOKIE_NAMES.All, true);
        setShowBanner(false);
    };

    const handleReject = () => {
        setCookieConsent(COOKIE_NAMES.All, false);
        setShowBanner(false);
    };

    const COOKIE_CONSENT_ACTION_BUTTONS: CookieButtonType[] = [
        {
            color: BUTTON_COLORS.White,
            buttonText: t("Button##more options"),
            action: () => setIsOpenCookiesDialog(true)
        },
        {
            color: BUTTON_COLORS.Primary,
            buttonText: t("Button##reject all"),
            action: handleReject
        },
        {
            color: BUTTON_COLORS.Primary,
            buttonText: t("Button##accept all"),
            action: handleAcceptAll
        }
    ];

    return showBanner ? (
        <>
            <div
                data-testid="cookies-banner"
                css={css({
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    bottom: "0",
                    backgroundColor: "#FFFFFF",
                    borderTop: `1px solid ${gray200}`,
                    boxShadow: "0px 6px 24px rgba(15, 23, 42, 0.16)",
                    zIndex: "1300",
                    left: 0,
                    right: 0,
                    columnGap: "24px",
                    padding: "23px 24px 24px",

                    "@media (max-width: 1439px)": {
                        flexDirection: "column",
                        alignItems: "flex-start",
                        rowGap: "16px"
                    },

                    "@media (max-width: 839px)": {
                        rowGap: "8px"
                    }
                })}
            >
                <div
                    css={css({
                        display: "flex",
                        flex: 1,
                        justifyContent: "end",
                        alignItems: "flex-start",
                        maxWidth: "566px",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",

                        "@media (max-width: 1439px)": {
                            maxWidth: "100%"
                        }
                    })}
                >
                    <CookiesMainDescription />
                </div>

                <div
                    css={css({
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        alignItems: "flex-start",
                        gap: "16px",
                        maxWidth: "566px",
                        width: "100%",

                        "@media (max-width: 1439px)": {
                            maxWidth: "100%"
                        },

                        "@media (max-width: 839px)": {
                            flexDirection: "column-reverse",
                            gap: "8px"
                        }
                    })}
                >
                    {COOKIE_CONSENT_ACTION_BUTTONS.map(
                        (button: CookieButtonType) => (
                            <Button
                                key={button.buttonText}
                                fullWidth
                                color={button.color}
                                size={BUTTON_SIZES.Normal}
                                variant={BUTTON_VARIANTS.TextOnly}
                                onClick={button.action}
                            >
                                {button.buttonText}
                            </Button>
                        )
                    )}
                </div>
            </div>

            <CookiesModal
                isOpen={isOpenCookiesDialog}
                onClose={setIsOpenCookiesDialog}
                setShowBanner={setShowBanner}
            />
        </>
    ) : null;
};

export default CookiesBanner;
