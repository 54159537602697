import { useContext, useEffect } from "react";

import ParamsContext from "../../../context/params/paramsContext";
import TableContext from "../../../context/table/tableContext";
import {
    API_IS_BEING_CANCELLED,
    useApi,
    useIsMounted,
    useParamsFromUrlAndBrowser
} from "../../../shared";

export const useTableData = (
    getInnerTableRowsData?: (
        rows: any,
        isTableLoading: boolean,
        totalRows: number
    ) => void
) => {
    const { queryParams, stringParams, reloadItems } =
        useContext(ParamsContext);

    const {
        rows,
        customData,
        isInnerTable,
        tableName,
        customResource,
        sortFromNewest,
        innerTableStringParams,
        isTableLoading,
        totalRows,
        setTableData,
        setTableLoading
    } = useContext(TableContext);

    const isMounted = useIsMounted();
    const { cancelSource, isCanceled, queryData, handleResponse } = useApi();
    const { stringifiedCurrentParams } = useParamsFromUrlAndBrowser();

    const reloadItem = reloadItems[tableName];

    const getDataOfAllRows = async () => {
        !isTableLoading && setTableLoading(true);

        try {
            const parameters = isInnerTable
                ? innerTableStringParams
                : stringParams;

            const hasSort =
                !isInnerTable &&
                !Object.keys(queryParams).includes("sort") &&
                sortFromNewest;

            const response = await queryData(
                customResource || tableName,
                parameters,
                hasSort
            );

            if (isMounted) {
                const {
                    data: { current_page, data, per_page, total }
                } = response;

                const payload = {
                    rows: data,
                    currentPage: current_page,
                    rowsPerPage: per_page,
                    totalRows: total
                };

                setTableData(payload);
            }
        } catch (error) {
            if (isMounted && !isCanceled(error)) {
                handleResponse(error);

                const payload = {
                    rows: [],
                    currentPage: 1,
                    rowsPerPage: 0,
                    totalRows: 0
                };

                setTableData(payload);

                getInnerTableRowsData &&
                    getInnerTableRowsData(rows, isTableLoading, 0);
            }
        }
    };

    // To avoid duplicate requests when going through pages when filters are applied
    const areSameParams = stringParams === stringifiedCurrentParams;

    // Get main table data
    useEffect(() => {
        !customData && !isInnerTable && areSameParams && getDataOfAllRows();

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, [stringParams, areSameParams, reloadItems[tableName]]);

    // Get inner table data
    useEffect(() => {
        !customData && isInnerTable && getDataOfAllRows();

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, [innerTableStringParams, customResource, reloadItem]);

    useEffect(() => {
        getInnerTableRowsData &&
            getInnerTableRowsData(rows, isTableLoading, totalRows);
        // eslint-disable-next-line
    }, [rows, isTableLoading]);
};
