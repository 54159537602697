import { useContext, useState, useEffect, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

import { CompanyProps } from "./types";

import EditComponent from "../EditDropdown";
import { getFilteredValues } from "../functions";

import { EDIT_TYPE } from "../../../types/enums";

import EditSection from "../../../../EditSection";

import ParamsContext from "../../../../../../context/params/paramsContext";
import TableContext from "../../../../../../context/table/tableContext";
import {
    ENDPOINTS,
    FILTER_NAMES,
    Query,
    useApi
} from "../../../../../../shared";

const Company = ({
    isEditable,
    value,
    toggleEditable,
    changeValue
}: CompanyProps) => {
    const { queryParams, updateFilterParams } = useContext(ParamsContext);
    const { detailsData } = useContext(TableContext);

    const { imei, company_id, company_name } = detailsData;

    const { t } = useTranslation();
    const { updateData, handleResponse } = useApi();

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        toggleEditable(EDIT_TYPE.Company, false);

        changeValue(
            EDIT_TYPE.Company,
            company_id !== null ? { id: company_id, name: company_name } : null
        );

        // eslint-disable-next-line
    }, [imei, company_name, company_id]);

    const edit = () => toggleEditable(EDIT_TYPE.Company, true);
    const cancel = () => toggleEditable(EDIT_TYPE.Company, false);

    const update = async (e: SyntheticEvent) => {
        e.preventDefault();

        if (value?.id !== company_id) {
            try {
                setLoading(true);

                const response = await updateData(ENDPOINTS.Devices, imei, {
                    company_id: value ? value.id : null
                });

                changeValue(EDIT_TYPE.Company, value, true);
                handleResponse(response);
            } catch (error) {
                handleResponse(error);
            }
        } else {
            cancel();
        }

        setLoading(false);
    };

    const handleChange = (companyValue: Query | null) =>
        changeValue(EDIT_TYPE.Company, companyValue);

    const filterDevices = () => {
        const companyId: number | null = detailsData.company_id;
        const companyName: string | null = detailsData.company_name;

        if (companyId !== null && companyName !== null) {
            const rootCompanyId = queryParams[FILTER_NAMES.RootCompany];

            const filteredValues = getFilteredValues(
                FILTER_NAMES.CompanyId,
                companyId,
                companyName
            );

            updateFilterParams(
                {
                    [FILTER_NAMES.RootCompany]: rootCompanyId,
                    [FILTER_NAMES.CompanyId]: String(companyId)
                },
                filteredValues
            );
        }
    };

    return (
        <EditSection
            sectionName={t("Table##company")}
            update={update}
            isLoading={isLoading}
            isEditable={isEditable}
            edit={edit}
            cancel={cancel}
            value={value}
            copiedValue={value?.name}
        >
            <EditComponent
                isEditable={isEditable}
                value={value}
                resource={ENDPOINTS.Companies}
                handleChange={handleChange}
                onClick={filterDevices}
            />
        </EditSection>
    );
};

export default Company;
