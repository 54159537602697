import SingleLoader from "./SingleLoader";

import { useMediaQueries } from "../../../../../shared";

const Loader = () => {
    const { toMd, fromLg } = useMediaQueries();

    return (
        <>
            <SingleLoader />
            <SingleLoader />
            {(toMd || fromLg) && <SingleLoader />}
        </>
    );
};

export default Loader;
