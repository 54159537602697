/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import { EnquiryTextProps } from "../types";
import TextLink, { TEXT_LINK_SIZES } from "../../DesignComponents/TextLink";
import ThemeContext from "../../../context/theme/themeContext";

const EnquiryText = ({ primaryText, linkText, ...props }: EnquiryTextProps) => {
    const {
        colorsFacelift: { textDark }
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                color: textDark,
                fontSize: "14px",
                fontWeight: 600,
                letterSpacing: "0.1px",
                marginTop: "16px"
            })}
        >
            <span>{primaryText} </span>

            <TextLink {...props} size={TEXT_LINK_SIZES.Normal}>
                {linkText}
            </TextLink>
        </div>
    );
};

export default EnquiryText;
