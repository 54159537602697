export const API_IS_BEING_CANCELLED = "Api is being cancelled";
export const COMPANY_FILTER = "companyFilter";
export const DESKTOP_DRAWER_OPEN = "DesktopDrawerOpen";
export const FIND_TOOL_FILTER = "findToolFilter";
export const INVALID_DATE = "invalidDate";
export const IS_DEVICE_STATISTICS_OPEN = "isDeviceStatisticsOpen";
export const I18_NEXT_LANG = "i18nextLng";
export const LAST_2FA_ENABLE_SUGGESTION = "last2FaEnableSuggestion";
export const NOT_ASSIGNED = "not assigned";
export const ROOT_COMPANY = "rootCompany";
export const SPEC_ID_NOT_ASSIGNED_VALUE = "null,0,1";
