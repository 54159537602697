/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import TextLink, { TEXT_LINK_SIZES } from "../../../DesignComponents/TextLink";

export const CookiesMainDescription = () => {
    const { t } = useTranslation();
    return (
        <p css={css({ margin: "0" })} data-testid="cookies-main-description">
            {t("Cookies##cookie banner message")}
            <TextLink
                href="https://teltonika-iot-group.com/about-us/policies-certificates/cookies-policy"
                target="_blank"
                rel="noreferrer"
                size={TEXT_LINK_SIZES.Normal}
                css={css({ display: "inline" })}
            >
                {t("Cookies##cookie policy")}
            </TextLink>
            .
        </p>
    );
};
