import { SetStateAction } from "react";
import { AxiosResponse } from "axios";

import { ENDPOINTS } from "../../../../../../shared";

const sendCodeToEmail = async (
    setLoading: (value: SetStateAction<boolean>) => void,
    successCallback: () => void,
    postData: (
        resource: string,
        payload?: any
    ) => Promise<AxiosResponse<any, any>>,
    handleResponse: (res: any) => void
) => {
    try {
        setLoading(true);
        await postData(ENDPOINTS.TfaSendEmail);
        successCallback();
    } catch (error) {
        handleResponse(error);
    }

    setLoading(false);
};

export { sendCodeToEmail };
