/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import { BorderFrameProps } from "../types";

import ThemeContext from "../../../../../context/theme/themeContext";

const BorderFrame = ({ children }: BorderFrameProps) => {
    const {
        colorsFacelift: { gray200 }
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                border: `1px solid ${gray200}`,
                borderRadius: "12px"
            })}
        >
            {children}
        </div>
    );
};

export { BorderFrame };
