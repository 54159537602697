import { TableInitialState } from "../types";

import { UserWithCompanyAttributes } from "../../auth/types";

import {
    ADMIN_MANAGER_USER,
    FILE_TYPES,
    ID_TYPE,
    QueryParamName,
    QueryParams,
    SYSTEM_USER,
    TABLE_NAMES,
    TASK_STATUSES,
    TELTONIKA_FILES_COMPANY,
    isAllowedToEditCompany
} from "../../../shared";

export const getActiveQueryParams = (
    isEmpty: boolean,
    state: TableInitialState,
    queryParams: QueryParams
) => {
    if (!isEmpty) {
        const allQueryParams = {
            ...state.innerTableQueryParams,
            ...queryParams
        };

        Object.entries(allQueryParams).forEach(([key, value]) => {
            if (value.length === 0) {
                delete allQueryParams[key as QueryParamName];
            }
        });

        return allQueryParams;
    } else {
        return {};
    }
};

export const updateSelectedRows = (state: TableInitialState, rowId: any) => {
    const rowIndex = state.selectedRows.findIndex(
        selectedRow => selectedRow === rowId
    );

    if (rowIndex === -1) {
        return [...state.selectedRows, rowId];
    } else {
        return state.selectedRows.filter(selectedRow => selectedRow !== rowId);
    }
};

export const deleteUpdatedQueryParams = (
    initialParams: QueryParamName[],
    key: QueryParamName,
    isFilter: (paramName: string) => boolean,
    updatedQueryParams: QueryParams
) => {
    if ((!initialParams.includes(key) && isFilter(key)) || key === "query") {
        delete updatedQueryParams[key];
    }
};

export const getUpdatedRowsAfterSingleRowUpdate = (
    rows: any[],
    idType: ID_TYPE,
    itemId: number,
    newData: any
) => rows.map(row => (row[idType] === itemId ? newData : row));

export const getSelectableRows = (
    name: TABLE_NAMES,
    rows: any[],
    user: UserWithCompanyAttributes | null
) => {
    if (name === TABLE_NAMES.Tasks) {
        return rows.filter(
            ({ status_id }: { status_id: TASK_STATUSES }) =>
                status_id === TASK_STATUSES.Pending ||
                status_id === TASK_STATUSES.Running
        );
    }

    if (name === TABLE_NAMES.Files) {
        const isAdminManagerUser = user?.role! >= ADMIN_MANAGER_USER;

        const canDeletefile = (type: FILE_TYPES) => {
            switch (type) {
                case FILE_TYPES.Dtb:
                case FILE_TYPES.FirmwareBundle:
                    return isAdminManagerUser;
                case FILE_TYPES.BundleBleFw:
                case FILE_TYPES.BundleCanFw:
                case FILE_TYPES.BundleMainDtb:
                case FILE_TYPES.BundleMainFw:
                    return false;
                default:
                    return true;
            }
        };

        return rows.filter(
            ({ company_id, type }: any) =>
                company_id !== TELTONIKA_FILES_COMPANY && canDeletefile(type)
        );
    }

    if (name === TABLE_NAMES.Companies) {
        return rows.filter((row: any) => {
            const isNotUserCompany = user?.company_id !== row.id;
            const isSystemUser = user?.role! >= SYSTEM_USER;

            return (
                isNotUserCompany &&
                isAllowedToEditCompany(isSystemUser, row.type)
            );
        });
    }

    if (name === TABLE_NAMES.Users) {
        return rows.filter(({ id }: any) => user?.id !== id);
    }

    return rows;
};

export const getUpdatedSelectedRows = (
    currentSelectedRows: number[],
    selectableRows: any[],
    idType: ID_TYPE
) => {
    const selectedRowsCopy = [...currentSelectedRows];

    return selectedRowsCopy.filter(rowId => {
        return selectableRows.some(
            selectableRow => selectableRow[idType] === rowId
        );
    });
};

export const getTotalRows = (totalRows: number, imeiArrayLength: number) => {
    const rowDifference = totalRows - imeiArrayLength;
    const rowCount = rowDifference > 0 ? rowDifference : 0;

    return totalRows === 0 ? 0 : rowCount;
};

export const getUpdatedRowsAfterMultipleRowsDelete = (
    stateRows: any[],
    idArray: number[],
    idType: ID_TYPE
) => {
    const currentRows = [...stateRows];

    return currentRows.filter(row => {
        const imeiSet = new Set(idArray);

        return !imeiSet.has(row[idType]);
    });
};
