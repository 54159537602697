/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import SingleAttribute from "./SingleAttribute";

import { TaskAttributesProps } from "../types";

import ThemeContext from "../../../../../../context/theme/themeContext";

const TaskAttributes = ({ separateAttributes }: TaskAttributesProps) => {
    const { t } = useTranslation();

    const {
        colorsFacelift: { textPlaceholder, gray700 }
    } = useContext(ThemeContext);

    return (
        <>
            <h4
                css={css({
                    textTransform: "uppercase",
                    fontWeight: "700",
                    letterSpacing: "0.15px",
                    lineHeight: "20px",
                    marginBottom: "8px"
                })}
            >
                {t("Table##attributes")}
            </h4>

            <div
                css={css({
                    marginBottom: "24px"
                })}
            >
                {separateAttributes.map(([key, value]: any) => {
                    return (
                        <div
                            key={key}
                            css={css({
                                display: "flex",
                                gridTemplateColumns: "auto auto",

                                marginTop: "4px",

                                span: {
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    lineHeight: "20px",
                                    letterSpacing: "0.1px"
                                },

                                "span:first-of-type": {
                                    color: textPlaceholder,
                                    marginRight: "8px"
                                },

                                "span:last-of-type": {
                                    color: gray700
                                }
                            })}
                        >
                            <SingleAttribute keyName={key} value={value} />
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default TaskAttributes;
