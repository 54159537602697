import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const TwoFactorDisabledIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg
                width="200"
                height="200"
                viewBox="0 0 200 200"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="200" height="200" fill="white" />

                <path
                    d="M35 188.332H165"
                    stroke="#70B8FF"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />

                <path
                    d="M140.594 23.03C128.341 23.03 116.885 19.6264 107.106 13.7158L106.874 13.583C102.524 10.9431 97.0945 11.0593 92.7778 13.749C92.5121 13.915 92.2963 14.0644 92.0639 14.1807C82.4674 19.809 71.2771 23.0134 59.3563 23.0134C57.1481 23.0134 54.9732 22.8971 52.8314 22.6813C43.2848 21.7349 35 29.256 35 38.869V74.7643C35 113.864 57.0983 149.61 92.0805 167.109L100 171.077L107.139 167.524L107.92 167.126C142.902 149.643 165 113.881 165 74.7809V38.8856C165 29.2892 156.715 21.7682 147.169 22.7311C145.027 22.9469 142.835 23.0632 140.644 23.0632V23.03H140.594Z"
                    fill="url(#paint0_linear_11949_646)"
                />

                <path
                    d="M82.9803 76.0881V66.6074C82.9803 57.3635 90.4844 49.8594 99.7284 49.8594C108.972 49.8594 116.476 57.3635 116.476 66.6074V76.0881"
                    stroke="#70B8FF"
                    strokeWidth="8.33333"
                    strokeMiterlimit="10"
                />

                <path
                    d="M76.3212 76.4238H122.615C126.17 76.4238 129.07 79.309 129.07 82.879V111.279C129.07 123.33 119.293 133.107 107.242 133.107H91.6941C79.6435 133.107 69.866 123.33 69.866 111.279V82.879C69.866 79.3236 72.7511 76.4238 76.3212 76.4238Z"
                    fill="white"
                />

                <path
                    d="M106.528 103.818C106.528 100.132 103.541 97.1445 99.8542 97.1445C96.1676 97.1445 93.1804 100.132 93.1804 103.818C93.1804 106.412 94.6667 108.656 96.8233 109.749V114.558C96.8233 116.233 98.1785 117.588 99.8542 117.588C101.53 117.588 102.885 116.233 102.885 114.558V109.749C105.042 108.641 106.528 106.397 106.528 103.818Z"
                    fill="#70B8FF"
                />

                <g opacity="0.5">
                    <path
                        d="M106.528 103.817C106.528 106.411 105.042 108.655 102.885 109.763V114.571C102.885 116.247 101.53 117.602 99.8542 117.602C99.0964 117.602 98.4116 117.325 97.8724 116.859C98.1639 116.946 98.4553 116.99 98.7759 116.99C100.452 116.99 101.807 115.635 101.807 113.959V109.151C102.186 108.961 102.55 108.728 102.885 108.466C104.444 107.242 105.45 105.347 105.45 103.22C105.45 100.947 104.298 98.9214 102.564 97.7266C104.91 98.7611 106.543 101.107 106.543 103.832L106.528 103.817Z"
                        fill="#70B8FF"
                    />
                </g>

                <defs>
                    <linearGradient
                        id="paint0_linear_11949_646"
                        x1="94.7701"
                        y1="285.62"
                        x2="99.751"
                        y2="11.6737"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#70B8FF" />
                        <stop offset="1" stopColor="#C5E2FF" />
                    </linearGradient>
                </defs>
            </svg>
        </SvgIcon>
    );
};

export default TwoFactorDisabledIcon;
