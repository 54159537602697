/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { SyntheticEvent, SetStateAction, useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteProps as MuiAutocompleteProps } from "@mui/material/Autocomplete";

import { useQueryDropdown } from "./hooks";
import { QueryDropdownProps } from "./types";

import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../DesignComponents/Tooltips";
import Tag, { TAG_COLORS, TAG_SIZES } from "../DesignComponents/Tag";
import Checkbox from "../DesignComponents/Checkbox";
import Autocomplete, {
    AutocompleteProps
} from "../DesignComponents/Autocomplete";

import ThemeContext from "../../context/theme/themeContext";
import CursorImage from "../../assets/customIcons/cursors/cursor-not-allowed.png";
import { NOT_ASSIGNED } from "../../shared";

const QueryDropdown = ({
    resource,
    idType,
    change,
    descriptionResource,
    textFieldParams,
    exceptions,
    hasOnlyInternalCompanies,
    hasNotAssigned,
    tooltipTitle,
    noOptionsTooltip,
    isRootRequired,
    getOptionDisabledTooltip,
    ...props
}: QueryDropdownProps &
    AutocompleteProps &
    Omit<
        MuiAutocompleteProps<any, boolean, boolean, boolean>,
        "renderInput" | "size" | "options"
    >) => {
    const {
        colorsFacelift: { gray700, textDarkDisabled }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    const { isLoading, options, query, setQuery } = useQueryDropdown({
        resource,
        idType,
        descriptionResource,
        exceptions,
        hasOnlyInternalCompanies,
        hasNotAssigned,
        hasUploadedFile: Boolean(noOptionsTooltip?.hasUploadedFile),
        isRootRequired,
        getOptionDisabledTooltip
    });

    const handleChange = (e: SyntheticEvent, option: any) => {
        setQuery("");
        change(e, option);
    };

    const getOptionLabel = (name: string) => {
        return name === NOT_ASSIGNED ? t("General##not assigned") : name;
    };

    const valueInArray = Array.isArray(props.value)
        ? props.value
        : [props.value];

    const isNoOptions =
        noOptionsTooltip &&
        !isLoading &&
        query.length === 0 &&
        options.length === 0;

    const isDisabled = isNoOptions ?? props.disabled;

    const cursorStyle = isNoOptions
        ? `url(${CursorImage}), default`
        : "default";

    const tooltipTitleText =
        tooltipTitle || (isNoOptions ? noOptionsTooltip.title : "");

    return (
        <Fragment>
            <Autocomplete
                {...props}
                disabled={isDisabled}
                options={[...valueInArray, ...options]}
                filterOptions={() => options}
                value={props.value}
                disableCloseOnSelect={props.multiple}
                getOptionLabel={option => getOptionLabel(option.name)}
                isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                }
                renderOption={(
                    propsData,
                    { id, name, disabledTooltip, description },
                    state
                ) => {
                    const { key, ...restProps } = propsData;
                    const isOptionDisabled = propsData["aria-disabled"];

                    return (
                        <TooltipGeneral
                            key={id}
                            title={disabledTooltip || ""}
                            placement={TOOLTIP_PLACEMENT.Top}
                        >
                            <li>
                                <span {...restProps}>
                                    <div
                                        css={css({
                                            display: "flex",
                                            flexDirection: "column"
                                        })}
                                    >
                                        <span>{getOptionLabel(name)}</span>

                                        {description && (
                                            <span
                                                css={css({
                                                    color: isOptionDisabled
                                                        ? textDarkDisabled
                                                        : gray700,
                                                    fontSize: "12px",
                                                    fontWeight: "400",
                                                    lineHeight: "16px",
                                                    letterSpacing: "0.4px"
                                                })}
                                            >
                                                {description}
                                            </span>
                                        )}
                                    </div>

                                    <div
                                        css={css({
                                            marginLeft: "auto",
                                            alignSelf: "flex-start"
                                        })}
                                    >
                                        {props.multiple && (
                                            <Checkbox
                                                checked={state.selected}
                                            />
                                        )}
                                    </div>
                                </span>
                            </li>
                        </TooltipGeneral>
                    );
                }}
                loading={isLoading}
                onChange={(event, option) => handleChange(event, option)}
                tooltipProps={{
                    title: tooltipTitleText,
                    buttonText: noOptionsTooltip?.buttonText,
                    onButtonClick: noOptionsTooltip?.onButtonClick
                }}
                textFieldParams={{
                    onChange: (e: {
                        target: { value: SetStateAction<string> };
                    }) => setQuery(e.target.value),
                    onBlur: () => setQuery(""),
                    ...(textFieldParams ? textFieldParams : {})
                }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index });

                        return (
                            <Tag
                                key={key}
                                {...tagProps}
                                size={TAG_SIZES.Tiny}
                                color={TAG_COLORS.Primary}
                                title={option?.name}
                            />
                        );
                    })
                }
                css={css({
                    "& .MuiAutocomplete-inputRoot": {
                        gap: "3px",
                        cursor: cursorStyle,

                        "& .Mui-disabled": {
                            cursor: cursorStyle
                        }
                    },

                    "& .MuiAutocomplete-tag": {
                        margin: "0"
                    }
                })}
            />
        </Fragment>
    );
};

export default QueryDropdown;
