/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import { InnerBottomProps } from "../types";

import ThemeContext from "../../../../../context/theme/themeContext";

const InnerBottom = ({ children }: InnerBottomProps) => {
    const {
        colorsFacelift: { gray200 }
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                padding: "24px",
                borderTop: `1px solid ${gray200}`
            })}
        >
            {children}
        </div>
    );
};

export { InnerBottom };
